import React, { useEffect } from 'react'
import { Form, Button, Container, Row, Col, Modal, Badge, Alert } from "react-bootstrap";
import CodeInput from 'react-code-input';
import { POST, GET } from '../../../controllers/endpoints.controller';
import { saveUserData } from '../../../controllers/user.controller';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEnvelope, faKey, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

export default function Login() {
    const [userLogged, setUserLogged] = React.useState(null);
    const [error, setError] = React.useState(<div></div>);
    const [errorFirstLogin, setErrorFirstLogin] = React.useState(<div></div>);
    const [errorPasswordRecovery, setErrorPasswordRecovery] = React.useState(<div></div>);
    const [errorPasswordRecoveryCode, setErrorPasswordRecoveryCode] = React.useState(<div></div>);
    const [errorPasswordNewPassword, setErrorPasswordNewPassword] = React.useState(<div></div>);
    const [successPasswordNewPassword, setSuccessPasswordNewPassword] = React.useState(<div></div>);
    const [showContentPasswordNewPassword, setShowContentPasswordNewPassword] = React.useState(true);

    const [passwordRecoveryEmail, setPasswordRecoveryEmail] = React.useState(null);
    const [passwordRecoveryCode, setPasswordRecoveryCode] = React.useState(null);
    const [passwordRecoveryUser, setPasswordRecoveryUser] = React.useState(null);

    const [showFirstLoginModal, setShowFirstLoginModal] = React.useState(false);
    const handleCloseFirstLoginModal = () => { setShowFirstLoginModal(false); };
    const handleShowFirstLoginModal = () => {
        setShowFirstLoginModal(true);
    };

    const [showResetPasswordSendEmailModal, setShowResetPasswordSendEmailModal] = React.useState(false);
    const handleCloseResetPasswordSendEmailModal = () => {
        setShowResetPasswordSendEmailModal(false);
        setErrorPasswordRecovery(<div></div>);
    };
    const handleShowResetPasswordSendEmailModal = () => {
        setShowResetPasswordSendEmailModal(true);
    };

    const [showResetPasswordCodeModal, setShowResetPasswordCodeModal] = React.useState(false);
    const handleCloseResetPasswordCodeModal = () => {
        setShowResetPasswordCodeModal(false);
        setErrorPasswordRecoveryCode(<div></div>);
    };
    const handleShowResetPasswordCodeModal = () => {
        setShowResetPasswordCodeModal(true);
    };

    const [showResetPasswordNewPasswordModal, setShowResetPasswordNewPasswordModal] = React.useState(false);
    const handleCloseResetPasswordNewPasswordModal = () => {
        setShowResetPasswordNewPasswordModal(false);
        setErrorPasswordNewPassword(<div></div>);
    };
    const handleShowResetPasswordNewPasswordModal = () => {
        setShowResetPasswordNewPasswordModal(true);
    };

    const handlePasswordRecoveryCode = (event) => {
        setPasswordRecoveryCode(event)
    };

    useEffect(() => {
        document.title = `Tasky`;
    }, []);


    const login = async function (email, password) {
        setError(<div></div>)

        let body;

        if (email && password) {
            body = {
                email: email,
                password: password
            }
        } else {
            body = {
                email: document.getElementById('textEmail').value,
                password: document.getElementById('textPassword').value.toString()
            }
        }

        const req = await POST('/sso/login', '', body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setUserLogged(res);
            if (res.firstLogin) {
                handleShowFirstLoginModal()
            } else {
                saveUserData(res);
                window.location.href = '/';
            }
        } else {
            setError(<Alert className='mt-2 w-100 content-table status' variant='danger' style={{ textAlign: 'center' }}>{res.message}</Alert>)
        }
    }

    const firstLogin = async function () {
        setErrorFirstLogin(<div></div>)

        if (userLogged != null) {
            const body = {
                userId: userLogged.id,
                currentPassword: document.getElementById('textPassword').value.toString(),
                newPassword: document.getElementById('textNewPassword').value.toString(),
                newPasswordConfirm: document.getElementById('textNewPasswordConfirm').value.toString()
            }

            const req = await POST('/sso/firstLogin', userLogged.token, body);
            const res = await req.json();

            if (req.status === 201) {
                console.log(res)
                login(userLogged.email, document.getElementById('textNewPassword').value.toString())
            } else {
                let errorMessage = "Error";

                switch (req.status) {
                    case 314:
                        errorMessage = "Las contraseñas no coinciden";
                        break;
                    case 315:
                        errorMessage = "La contraseña no es segura";
                        break;
                }

                setErrorFirstLogin(<Alert className='mt-2 w-100 content-table status' variant='danger' style={{ textAlign: 'center' }}>{errorMessage}</Alert>)
                console.log(res)
            }
        }
    }

    const redirectToGoogleLogin = async function () {
        const req = await GET('/sso/login/google', '');
        const res = await req.json();

        if (req.status === 200) {
            window.location.href = res.url;
        } else {
            alert(res.message);
        }
    }

    const requestPasswordRecovery = async function () {
        setErrorPasswordRecovery(<div></div>)

        let body = {
            email: document.getElementById("textRecoveryEmail").value
        }

        const req = await POST('/sso/user/password/recovery/request', '', body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            if (res.result == true) {
                setPasswordRecoveryEmail(document.getElementById("textRecoveryEmail").value)
                handleShowResetPasswordCodeModal()
                handleCloseResetPasswordSendEmailModal()
            }
        } else {
            console.log(res)
            setErrorPasswordRecovery(<Alert className='mt-2 w-100 content-table status' variant='danger' style={{ textAlign: 'center' }}>{res.message}</Alert>)
        }
    }

    const checkPasswordRecoveryCode = async function () {
        setErrorPasswordRecoveryCode(<div></div>)

        let body = {
            email: passwordRecoveryEmail,
            recovery: passwordRecoveryCode
        }

        const req = await POST('/sso/user/password/recovery/verify', '', body);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPasswordRecoveryUser(res)
            handleCloseResetPasswordCodeModal()
            handleShowResetPasswordNewPasswordModal()
        } else {
            setErrorPasswordRecoveryCode(<Alert className='mt-2 w-100 content-table status' variant='danger' style={{ textAlign: 'center' }}>{res.message}</Alert>)
        }
    }

    const changePassword = async function () {
        setErrorPasswordNewPassword(<div></div>)

        let body = {
            userId: passwordRecoveryUser.id,
            recovery: passwordRecoveryCode,
            newPassword: document.getElementById('textNewPassword').value.toString(),
            newPasswordConfirm: document.getElementById('textNewPasswordConfirm').value.toString()
        }
        console.log(body)
        const req = await POST('/sso/user/password/recover', '', body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            setShowContentPasswordNewPassword(false)
            setSuccessPasswordNewPassword(<Alert className='mt-2 w-100 content-table status' variant='success' style={{ textAlign: 'center' }}>Contraseña modificada correctamente</Alert>)
        } else {
            setErrorPasswordNewPassword(<Alert className='mt-2 w-100 content-table status' variant='danger' style={{ textAlign: 'center' }}>{res.message}</Alert>)
        }
    }

    return (
        <div className='login-bigContainer' style={{ backgroundColor: '#F8F9FC', justifyContent: 'center' }}>
            <Row>
                <div className="login-container">
                    <div className="login-card">
                        <div className="logo">
                            <img src="/logo_color.svg" width={130} alt="Tasky Logo" />
                        </div>

                        <Form className='mt-4'>
                            <Button className="d-flex align-items-center justify-content-center" id="btnGoogle" variant="primary" onClick={redirectToGoogleLogin}><FontAwesomeIcon icon={faGoogle} style={{ fontSize: 25, marginRight: 8, verticalAlign: 'middle' }} /><div style={{ verticalAlign: 'middle', textAlign: 'left' }}><p className='m-0'>Ingresar con Google</p><p className='mb-0' style={{ fontSize: 8, marginTop: '-1px' }}>CORREO INSTITUCIONAL</p></div></Button>

                            <div className="d-flex align-items-center my-3">
                                <hr className="flex-grow-1" />
                                <span className="mx-2" style={{ fontSize: 11 }}>O CON TU CORREO PERSONAL</span>
                                <hr className="flex-grow-1" />
                            </div>

                            <Form.Control className="" type="email" id="textEmail" placeholder="Correo electrónico" required />
                            <Form.Control className='mt-2' type="password" id="textPassword" placeholder="Contraseña" required />
                            {error}
                            <Row className='mt-2 mb-3'>
                                <Col style={{ textAlign: "center" }}>
                                    <a href='#' style={{ fontSize: 13 }} onClick={handleShowResetPasswordSendEmailModal}>Olvidé mi contraseña</a>
                                </Col>
                            </Row>
                            <Button className="d-flex align-items-center justify-content-center" id="btnLogin" variant="dark" onClick={login}><FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 22, marginRight: 8, verticalAlign: 'middle' }} /><div style={{ verticalAlign: 'middle', textAlign: 'left' }}><p className='m-0'>Ingresar</p></div></Button>

                        </Form>
                        <a href="https://wa.me/5491158402933"><p className='mb-0 mt-4' style={{ fontSize: 13 }} >Necesito ayuda</p></a>
                        <Link to="/privacidad"><p className='m-0' style={{ fontSize: 13 }}>Políticas de privacidad</p></Link>
                    </div>
                </div>
            </Row>

            <Modal show={showFirstLoginModal} centered backdrop="static" onHide={handleCloseFirstLoginModal}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={40} className='dropModalCont dropDownIcon' icon={faKey} style={{ minWidht: '20px' }} /><Modal.Title>Primer ingreso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Es la primera vez que ingresás a Tasky. Por favor, ingresá una nueva contraseña.

                    <Form.Control className='mt-2' type="password" id="textNewPassword" placeholder="Nueva contraseña" />
                    <Form.Control className='mt-2' type="password" id="textNewPasswordConfirm" placeholder="Confirmar nueva contraseña" />
                    {errorFirstLogin}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseFirstLoginModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>

                    <Button variant="primary" className='fs-14' onClick={firstLogin}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>

                </Modal.Footer>
            </Modal>

            <Modal show={showResetPasswordSendEmailModal} centered backdrop="static" onHide={handleCloseResetPasswordSendEmailModal}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={40} className='dropModalCont dropDownIcon' icon={faKey} style={{ minWidht: '20px' }} /><Modal.Title>Restablecer contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p className='mb-0' style={{ textAlign: 'center' }}>Ingresá tu correo y te enviaremos un código de verificación para validar tu cuenta.</p>
                    <p className='mt-1 mb-0' style={{ textAlign: 'center' }}>Este proceso servirá solamente si estás registrado con tu correo personal.</p>
                    <p className='mt-1 mb-0' style={{ textAlign: 'center' }}>En caso de no recordar la contraseña de tu cuenta de Google (institucional), deberás contactar al Colegio.</p>

                    <Form.Control className='mt-4' type="text" id="textRecoveryEmail" placeholder="Correo electrónico" />
                    {errorPasswordRecovery}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseResetPasswordSendEmailModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>

                    <Button variant="primary" className='fs-14' onClick={requestPasswordRecovery}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>

                </Modal.Footer>
            </Modal>

            <Modal show={showResetPasswordCodeModal} centered backdrop="static" onHide={handleCloseResetPasswordCodeModal}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={40} className='dropModalCont dropDownIcon' icon={faKey} style={{ minWidht: '20px' }} /><Modal.Title>Restablecer contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='m-0' style={{ textAlign: 'center' }}>Ingresá el código que enviamos a tu correo.</p>
                    <p className='mt-1 mb-0' style={{ textAlign: 'center' }}>Si no lo recibiste, verificá la carpeta de Correo no deseado.</p>
                    <p className='mt-1 mb-0' style={{ textAlign: 'center' }}>El código es válido durante 15 minutos.</p>

                    <div className='w-100 mt-3' style={{ textAlign: 'center' }} >
                        <CodeInput
                        type='text'
                            inputMode="numeric"
                            fields={6}
                            onChange={e => handlePasswordRecoveryCode(e)}
                        />
                    </div>

                    {errorPasswordRecoveryCode}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseResetPasswordCodeModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>

                    <Button variant="primary" className='fs-14' onClick={checkPasswordRecoveryCode}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>

                </Modal.Footer>
            </Modal>

            <Modal show={showResetPasswordNewPasswordModal} centered backdrop="static" onHide={handleCloseResetPasswordNewPasswordModal}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={40} className='dropModalCont dropDownIcon' icon={faKey} style={{ minWidht: '20px' }} /><Modal.Title>Restablecer contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showContentPasswordNewPassword == true &&
                        <>
                            <p className='mb-0' style={{ textAlign: 'center' }}>Ingresá una nueva contraseña.</p>

                            <Form.Control className='mt-3' type="password" id="textNewPassword" placeholder="Nueva contraseña" />
                            <Form.Control className='mt-2' type="password" id="textNewPasswordConfirm" placeholder="Confirmar nueva contraseña" />
                        </>
                    }
                    {successPasswordNewPassword}
                    {errorPasswordNewPassword}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseResetPasswordNewPasswordModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                    {showContentPasswordNewPassword == true && <Button variant="primary" className='fs-14' onClick={changePassword}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>}
                </Modal.Footer>
            </Modal>
        </div>
    );
}