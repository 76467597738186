import React, { useEffect, useState } from 'react'
import { Form, Button, Container, Row, Col, Modal, Badge, Alert, Card } from "react-bootstrap";
import { POST, GET } from '../../../controllers/endpoints.controller';
import { getMyStudents, getUserRole, getUserToken, saveUserData } from '../../../controllers/user.controller';
import { Link } from 'react-router-dom';

export default function ChooseRole() {
    const [childs, setChilds] = React.useState([]);

    useEffect(() => {
        document.title = `Tasky`;

        getMyChilds();
    }, []);

    const [mainId, setMainId] = useState(null)
    const saveMainId = (list) => {
        for(let r of list){
            if(!r.isChildUser){
                setMainId(r.id);
                break;
            }
        }
    }

    const getMyChilds = async function () {
        const req = await GET('/sso/childs/mine', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setChilds(res)
            console.log(res)
            saveMainId(res);
        } else {
            alert(res.message);
        }
    }

    const selectRole = async function (user) {
        user.hasChilds = true;
        user.mainId = mainId;
        saveUserData(user);
        if(user.Role.name == 'Familia' || user.Role.name == 'Padre-Madre-Tutor'){
            getMyStudents();
        }else{
            window.location.href = '/inicio';
        }
        
    }

    return (
        <div style={{backgroundColor:'#F8F9FC'}}>
            <Row>
                <div className="login-container">
                    <div className="login-card">
                        <div className="logo">
                            <img src="/logo_color.svg" width={110} alt="Tasky Logo" />
                        </div>
                        <p className='mt-4 mb-4 fs-14'>Notamos que tenés varios roles, seleccioná el rol con el que deseás ingresar:</p>

                        {childs.map((c, i) => (
                            <Col md={12}>
                                <Button className='p-4 mt-2 w-100' onClick={() => selectRole(c)}>{c.Role.name}</Button>
                                
                            </Col>
                        ))}
                    </div>
                </div>
            </Row>



        </div>
    );
}