import React, { useEffect } from 'react'
import { Row } from "react-bootstrap";
import { POST } from '../../../controllers/endpoints.controller';
import { getChilds, saveUserData, validateUser } from '../../../controllers/user.controller';

export default function GoogleAuth() {

    useEffect(() => {
        document.title = `Tasky`;
        getGoogleUserInfo();
    }, []);

    const getParam = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('code')) {
            return (window.location.search);
        } else {
            return -1;
        }
    }

    const getGoogleUserInfo = async function () {
        const req = await POST(`/sso/login/google/callback/`, '', {code: getParam()});
        const res = await req.json();

        if (req.status === 200) {
            saveUserData(res);
            getChilds();
        } else {
            window.location.href = "/no-habilitado";
        }
    }

    return (
        <div>
            <Row>
                Cargando...
            </Row>
        </div>
    );
}