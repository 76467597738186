import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faClipboardList, faClipboardUser, faEllipsisV, faFile, faFileArrowDown, faFileCircleExclamation, faFolderOpen, faPen, faPenFancy, faPlus, faTimes, faTrash, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { Badge, Button, Card, Col, Dropdown, DropdownMenu, Form, Modal, Row, Table, Spinner, ToggleButton } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getFamilyMemberId, getFamilyMemberToken, getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { useLocation } from "react-router-dom";
import Loading from '../../general-components/Loading';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import NotFound from '../../general-components/NotFound';
import InfoField from '../../general-components/InfoField';
import { downloadFile, formatNameV2 } from '../../../tools/tools';
import PDFViewer from 'mgr-pdf-viewer-react/dist/mgr-pdf-viewer-react';

function EvaluativeReportSecondaryLevelByStudents() {
    const PAGE_TITLE = "Calificaciones - Registro de valoración académica";
    const PAGE_ICON = faPen;
    const PREVIOUS_PAGE = "";
    const PREVIOUS_URL = "";

    const location = useLocation();
    const courseId = location.state ? location.state.course : 'Curso';

    let keys = 0;
    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        //getClassrooms();
        getPeriods();
    }, []);

    const [myRole, setMyRole] = useState(getUserRole());
    const [userToken, setUserToken] =  useState(myRole=='Familia' || myRole=='Padre-Madre-Tutor' ? getFamilyMemberToken() : getUserToken());
    const [periods, setPeriods] = useState([]);


    const getPeriods = async function () {
        const req = await GET(`/grades/periods/noGrouped`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
            getFileByPeriod(getFamilyMemberId(), res[0].id)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }
    const [loadedTable, setLoadedTable] = useState(true);

    const [selectedPeriodGeneralOption, setSelectedPeriodGeneralOption] = useState('Período');
    const [selectedPeriodGeneralId, setSelectedPeriodGeneralId] = useState(null);

    const handleSelectPeriodGeneral = (eventKey) => {
        setSelectedPeriodGeneralOption(periods[eventKey].name);
        setSelectedPeriodGeneralId(periods[eventKey].id);
        setLoadedTable(false)
        getFileByPeriod(getFamilyMemberId(), periods[eventKey].id)
    };

    const [showViewFileModal, setShowViewFileModal] = useState(false);
    const [actualViewFile, setActualViewFile] = useState(null);
    const [actualViewFileId, setActualViewFileId] = useState(null);
    const [actualViewFileName, setActualViewFileName] = useState("");
    const viewFile = async (userId, periodId) => {
        const req = await GET(`/grades/report/latest/byUserId&PeriodId/${userId}/${periodId}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res);
            if (res.extension.includes("pdf")) {
                setActualViewFile(res.file);
                setActualViewFileName(res.name);
                setActualViewFileId(res.id)
                setLoadedTable(true)
            } else {
                //downloadFile(re, `Informe.${res.extension}`);
            }
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [isFound, setIsFound] = useState(true)
    const getFileByPeriod = async function (userId, pid) {
        setLoadedTable(false);
        console.log(`/grades/report/latest/byUserId&PeriodId/${userId}/${pid}`)
        const req = await GET(`/grades/report/latest/byUserId&PeriodId/${userId}/${pid}`, userToken);
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            if (res.extension && res.extension.includes("pdf")) {
                setActualViewFile(res.file);
                setActualViewFileName(res.name);
                setActualViewFileId(res.id)
                setLoadedTable(true);
                setIsFound(true);
            } else{
                setIsFound(false);
            }
            setLoadedTable(true);
        } else if (req.status === 403) {
            logout();
        } else if(req.status === 404){
            setIsFound(false)
        }else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14">
                <div className='d-block d-lg-none' style={{ height: '70px' }}> </div>

                <Row className='toolbar m-0'>
                    <Col style={{ display: 'flex', flexDirection: 'row !important', alignItems: 'center' }}>
                        {PREVIOUS_PAGE != "" ?
                            <Link to={PREVIOUS_URL} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>

                    </Col>
                </Row>

                <Row className='mt-4' style={{ rowGap: '8px' }}>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-250">
                                    {selectedPeriodGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodGeneralOption}>
                                    {periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriodGeneral(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                    {actualViewFileId != null && <Button variant="secondary" onClick={() => downloadFile(actualViewFileId, 'Informe.pdf')} className='fs-14'><FontAwesomeIcon className='mr-10' icon={faFileArrowDown} />Descargar</Button>}
                    
                    </Col>
                </Row>

                <Card className='cards mt-3 fs-14 h-100-2 mobile-cards' style={{ overflow: 'auto' }}>

                    {/* <div className='justify-content-center'>
                            {selectedPeriodGeneralId != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='Seleccioná un período' />
                            }
                        </div> */}
                    <div>
                        {!loadedTable ?
                            <div className='justify-content-center'>
                                <Loading animation={'border'} classExtra='loading-100' />
                            </div>
                            : isFound && actualViewFile ?
                                <PDFViewer document={{
                                    base64: actualViewFile
                                }} />
                                :
                                <div className='w-100 justify-content-center' style={{ textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
                                    <NotFound classExtra="mt-5" iconFa={faFileCircleExclamation} text='No se ha encontrado un informe para el período seleccionado.' />
                                </div>
                        }
                    </div>
                </Card>
            </div >
        </div >
    )
}

export default EvaluativeReportSecondaryLevelByStudents;