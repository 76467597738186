import React, { useEffect, useState } from 'react'
import { Card, Button, Container, Row, Col, Modal, Badge, Alert, Carousel, Spinner, Form, Dropdown, } from "react-bootstrap";
import { POST, GET } from '../../../controllers/endpoints.controller';
import { faBell, faCalendar, faCheck, faChevronLeft, faChevronRight, faClock, faCog, faEye, faFile, faFileAlt, faFileCircleExclamation, faMessage, faMicrophone, faPlus, faRefresh, faTimes, faUserCircle, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import Navbar from '../../navbar/Navbar'
import EventModal from '../calendar/EventModal';
import NotFound from '../../general-components/NotFound';
import Calendar from '../calendar/Calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getFamilyMemberId, getFamilyMembersAmount, getUserRole, getUserToken, logout, validateToken, validateUser } from '../../../controllers/user.controller';
import Loading from '../../general-components/Loading';
import Courses from '../courses/CoursesCard';
import moment from 'moment';
import { fa42Group } from '@fortawesome/free-brands-svg-icons';
import Chat from '../communications/Chat';
import { icon } from '@fortawesome/fontawesome-svg-core';
import 'moment/locale/es';
moment.locale('es');

export default function Home() {
    const PAGE_TITLE = "Inicio";

    const [myUserRole, setMyUserRole] = useState(null)

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
        moment.locale('es');
        getLastCommunication()
        getNews();
        setMyUserRole(getUserRole())
    }, []);

    useEffect(() => {
        if (myUserRole != null && myUserRole == 'Familia' || myUserRole == 'Padre-Madre-Tutor') {
            getMyChildren();
        }
    }, [myUserRole])

    const [lastCommunication, setLastCommunication] = useState({})

    const [isCarouselLoaded, setIsCarouselLoaded] = useState(false);
    const [newsItems, setNewsItems] = useState([]);

    const customPrevIcon = <FontAwesomeIcon className='prev-next-icon' icon={faChevronLeft} style={{ justifyContent: 'flex-start !important' }} />;
    const customNextIcon = <FontAwesomeIcon className='prev-next-icon' icon={faChevronRight} style={{ justifyContent: 'flex-end !important' }} />;

    //Modal calendar event creation
    const [showCreateEventCalendar, setShowCreateEventCalendar] = useState(false);

    const handleCloseCreateEventCalendar = () => setShowCreateEventCalendar(false);
    const handleShowCreateEventCalendar = () => setShowCreateEventCalendar(true);

    const getNews = async function () {
        const req = await GET(`/communication/news/byRoleId/byClassroomId`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            let news = [];
            setIsCarouselLoaded(true);
            for (let n of res) {
                news.push({
                    img: n.File.file,
                    colorBack: n.color
                })
            }

            setNewsItems(news);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    //Modal new communication
    const [showComu, setShowComu] = useState(false);

    const handleShowComu = () => {
        setNewComuTitle('');
        setNewComuDescrp('')
        setShowComu(true)
    };
    const handleCloseModalComu = () => {
        setShowComu(false);
    }

    const [newComuTitle, setNewComuTitle] = useState('');
    const [newComuDescrp, setNewComuDescrp] = useState('');
    const handleComuTitle = (event) => {
        setNewComuTitle(event.target.value);
    };
    const handleComuDescrp = (event) => {
        setNewComuDescrp(event.target.value);
    };

    const [isLastCommLoading, setIsLastCommLoading] = useState(true)

    const getLastCommunication = async function () {
        setIsLastCommLoading(true)
        const req = await GET(`/communication/latest/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setIsLastCommLoading(false)
            setLastCommunication(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [myChildren, setMyChildren] = useState([
        {
            name: 'Sabrina',
            surname: 'Martinez',
            avatarFile: 'https://media.admagazine.com/photos/663ab8194cc60b7bee3bfff7/1:1/w_2250,h_2250,c_limit/flor-de-mayo.jpg'
        },
        {
            name: 'Sabrina',
            surname: 'Martinez',
            avatarFile: 'https://media.admagazine.com/photos/663ab8194cc60b7bee3bfff7/1:1/w_2250,h_2250,c_limit/flor-de-mayo.jpg'
        },
        {
            name: 'Sabrina',
            surname: 'Martinez',
            avatarFile: 'https://media.admagazine.com/photos/663ab8194cc60b7bee3bfff7/1:1/w_2250,h_2250,c_limit/flor-de-mayo.jpg'
        },
        {
            name: 'Sabrina',
            surname: 'Martinez',
            avatarFile: 'https://media.admagazine.com/photos/663ab8194cc60b7bee3bfff7/1:1/w_2250,h_2250,c_limit/flor-de-mayo.jpg'
        }
    ])
    const getMyChildren = async function () {
        const req = await GET(`/sso/families/members/children/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setMyChildren(res)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }
    // /communication/latest/mine
    /*const [notifications, setNotifications] = useState([
        {
            date: "2024-08-27T15:50:00",
            title: 'Nueva nota',
            body: "Se agregaron notas de matemática.",
            action: "/inicio",
            isSeen: false,
        },
        {
            date: "2024-08-26T14:30:00",
            title: 'Nueva nota',
            body: "Se agregaron notas de biología.",
            action: "/inicio",
            isSeen: false,
        },
        {
            date: "2024-08-25T14:30:00",
            title: 'Nueva nota',
            body: "Se agregaron notas de lengua.",
            action: "/inicio",
            isSeen: true,
        },

    ])*/

    const [notifications, setNotifications] = useState([])

    const getMyNotifications = async function () {
        setIsLastCommLoading(true)
        const req = await GET(`/notifications/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setNotifications(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const formatDate = (dateString) => {
        const date = moment(dateString);
        const now = moment();

        const minutesDifference = now.diff(date, 'minutes');
        const hoursDifference = now.diff(date, 'hours');
        const daysDifference = now.diff(date, 'days');

        if (minutesDifference < 60) {
            return `hace ${minutesDifference} minuto${minutesDifference > 1 ? 's' : ''}`;
        }

        if (hoursDifference < 24) {
            return `hace ${hoursDifference} hora${hoursDifference > 1 ? 's' : ''}`;
        }

        if (daysDifference <= 6) {
            return `hace ${daysDifference} día${daysDifference > 1 ? 's' : ''}`;
        }
        const formatString = date.isSame(now, 'year') ? 'DD/MM HH:mm' : 'DD/MM/YYYY HH:mm';
        return date.format(formatString);
    }

    const handkeClickNotification = (action) => {
        const link = document.createElement("a");
        link.href = action;
        link.click();
    }
    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <div className='d-block d-lg-none' style={{ height: '70px' }}>

                </div>
                <Row className='toolbar m-0'>
                    <Col>
                        <img src='/demo/svp.svg' height={35} className='me-2' />Colegio Fasta San Vicente de Paúl
                    </Col>
                </Row>
                {/* Vista desktop ------------------------------------------------------------------------*/}
                <div className="d-none d-lg-block" >
                    <Row className='mt-4'>
                        <Col md={8} xs={12} className='ps-0'>
                            <Row>
                                <Col className='ps-0 pe-0'>
                                    <Card className='p-0 cards overflow-hidden' >
                                        <Carousel prevIcon={customPrevIcon} nextIcon={customNextIcon}>
                                            {!isCarouselLoaded ?
                                                <div style={{ height: '45vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Loading animation={'border'} />
                                                </div> :
                                                newsItems.length > 0 ?
                                                    newsItems.map((element, i) => {
                                                        return (<Carousel.Item key={i} interval={3000} style={{ backgroundColor: element.colorBack }} >
                                                            <div className='justify-content-center' >
                                                                <img style={{ height: '45vh' }} src={element.img} />
                                                            </div>
                                                        </Carousel.Item>)
                                                    })
                                                    : <div style={{ height: '45vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><NotFound text={'No se han encontrado novedades'} iconFa={faFileCircleExclamation} /></div>
                                            }
                                        </Carousel>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col className='p-0' md={6}>
                                    <Card className='home-mini-cards cards '>
                                        {myUserRole != 'Familia' && myUserRole != 'Padre-Madre-Tutor' ? <>
                                            <Row className='align-items-center mb-3'>

                                                <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFile}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Comunicados</span></Col>

                                                {/*<ActionButton iconFa={faPlus} func={handleShowComu} classExtra='me-2' />*/}
                                            </Row>
                                            <Row>
                                                <div className='pt-0 pb-0 ps-2 pe-2' style={{ maxHeight: 'calc(55vh - 203px)', height: 'calc(55vh - 203px)' }}>
                                                    {isLastCommLoading ? <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                        <Loading animation={'border'} />
                                                    </div> :
                                                        Object.keys(lastCommunication).length == 0 ?
                                                            <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                                <NotFound iconFa={faFileCircleExclamation} text='No se han encontrado comunicados' />
                                                            </div> :

                                                            <Card className='greyBackCard cards interCards pt-0 pb-0 pe-1 ps-1' style={{ maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
                                                                <Row className='communication-card p-0' style={{ background: '', borderRadius: 8, border: "1px solid #F0F0F0", position: 'relative', maxHeight: '100%', height: '100%' }} >
                                                                    <div className='p-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '34px', backgroundColor: '#F0F0F0' }}>
                                                                        <p className='mb-0 fs-14 fw-600'>{lastCommunication.title}</p>
                                                                    </div>
                                                                    <div style={{ backgroundColor: 'white', overflowY: 'hidden', maxHeight: 'calc(100% - 75px)', height: 'calc(100% - 75px)' }} className='pb-2 pt-2'> {/* borderLeft: '4px solid #F0F0F0', borderRight: '4px solid #F0F0F0 */}
                                                                        <Row style={{ overflowY: 'hidden', maxHeight: '100%', height: '100%' }}>

                                                                            <Col md={11} className='ps-0 pe-0'>
                                                                                <div dangerouslySetInnerHTML={{ __html: lastCommunication.content }} />
                                                                            </Col>
                                                                            <Col md={1} className='align-center-vertically' style={{ justifyContent: 'flex-end', height: '100%' }}>
                                                                                <Link to={'/comunicaciones'} ><FontAwesomeIcon className='prev-next-icon greyHover' icon={faChevronRight} /></Link>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className='p-0' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }}>
                                                                        <Row style={{ width: '100%', backgroundColor: '#F0F0F0' }} className='ps-3 pe-3 pt-2 pb-2'>
                                                                            <Col md={12} className='ps-0 pe-0'>
                                                                                <div className='align-center-vertically' style={{ justifyContent: 'space-between' }}>
                                                                                    {lastCommunication.User && <div className='align-center-vertically me-2 fs-11'>
                                                                                        <div className='fitContentWidth'>
                                                                                            <div className='studentImg circle-container me-2' style={{ borderColor: 'white', width: '24px', height: '24px' }}>
                                                                                                <img className='' src={lastCommunication.User.avatarFile} alt={lastCommunication.User.name} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {lastCommunication.User.surname ? lastCommunication.User.surname.toUpperCase() : ""}, {lastCommunication.User.name}
                                                                                    </div>}
                                                                                    <p className='smallFontSize mb-0'>{lastCommunication.sendDate && `${moment(lastCommunication.sendDate).format("DD/MM/YY HH:mm")} hs`} </p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Row>

                                                            </Card>}

                                                </div>
                                            </Row>
                                        </> :
                                            <>
                                                <Row className='align-items-center mb-3'>

                                                    <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faBell}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Notificaciones</span></Col>

                                                    {/*<ActionButton iconFa={faPlus} func={handleShowComu} classExtra='me-2' />*/}
                                                </Row>
                                                <Row>
                                                    <div className='pt-0 pb-0 ps-2 pe-2' style={{ maxHeight: 'calc(55vh - 203px)', height: 'calc(55vh - 203px)', overflowY: 'auto' }}>
                                                        {/* isLastCommLoading ? <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                            <Loading animation={'border'} />
                                                        </div> : */
                                                            notifications.length == 0 ?
                                                                <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                                    <NotFound iconFa={faBell} text='No tenés notificaciones' />
                                                                </div> :
                                                                notifications.map((r, i) => (
                                                                    <Card className={`${!r.isSeen && 'greyBackCard'} cards interCards pt-1 pb-1 pe-1 ps-1 pointer notificationCard`} onClick={() => handkeClickNotification(r.action)}>
                                                                        <Row className=' p-0' style={{ background: '', borderRadius: 8, /* border: "1px solid #F0F0F0"  */ }} >
                                                                            <Col className='pe-1'>
                                                                                <div className='fw-500 notificationTitle'>{r.title}</div>
                                                                            </Col>
                                                                            <Col className=' ps-0' style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                                                <div className='align-center-vertically'>
                                                                                    <div className='smallFontSize' style={{ textAlign: 'right' }}>{formatDate(r.date)}</div>
                                                                                    </div>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <div style={{ marginTop: '-2px' }}>{r.body}</div>
                                                                            </Col>
                                                                        </Row>
                                                                        {!r.isSeen && <div style={{ borderRadius: '50%', backgroundColor: '#0480FD', height: '6px', width: '6px', position: 'absolute', right: '6px', top: '6px' }}></div>}
                                                                    </Card>))}

                                                    </div>
                                                </Row>
                                            </>
                                        }</Card>
                                </Col>
                                <Col md={6} className='pe-0'>
                                    <Card className='home-mini-cards cards '>
                                        <Row className='align-items-center mb-2'>


                                            <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faCalendar}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Calendario</span></Col>

                                            {/*<ActionButton iconFa={faPlus} classExtra='me-2' func={handleShowCreateEventCalendar} />  Determinar link to */}
                                        </Row>
                                        <Row className=''>
                                            <Calendar />
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4} className='p-0'>
                            {myUserRole != null && <Row>
                                {myUserRole != 'Familia' && myUserRole != 'Padre-Madre-Tutor' ? <Col className='p-0'>
                                    <Courses />
                                </Col>
                                    :
                                    <Col className='p-0'>
                                        <div className='mb-4'>
                                            {/* <Chat heigth={'h-45'} /> */}
                                            <Card className='home-mini-cards cards h-45'>
                                                <Row className='align-items-center mb-3'>
                                                    <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faMessage}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Mensajes</span></Col>
                                                </Row>
                                                <Row>
                                                    <div style={{ height: '35vh', justifyContent: 'center' }} className='align-center-vertically'>
                                                        <NotFound text={'Próximamente encontrarás aquí tus mensajes'} iconFa={faMessage} />
                                                    </div>
                                                </Row>
                                            </Card>
                                        </div>
                                        <Card className='home-mini-cards cards '>
                                            <Row className='align-items-center mb-3'>
                                                <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFile}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Comunicados</span></Col>
                                            </Row>
                                            <Row>

                                                <div className='pt-0 pb-0 ps-2 pe-2' style={{ maxHeight: 'calc(55vh - 203px)', height: 'calc(55vh - 203px)' }}>
                                                    {isLastCommLoading ? <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                        <Loading animation={'border'} />
                                                    </div> :
                                                        Object.keys(lastCommunication).length == 0 ?
                                                            <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                                <NotFound iconFa={faFileCircleExclamation} text='No se han encontrado comunicados' />
                                                            </div> :

                                                            <Card className='greyBackCard cards interCards pt-0 pb-0 pe-1 ps-1' style={{ maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
                                                                <Row className='communication-card p-0' style={{ background: '', borderRadius: 8, border: "1px solid #F0F0F0", position: 'relative', maxHeight: '100%', height: '100%' }} >
                                                                    <div className='p-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '34px', backgroundColor: '#F0F0F0' }}>
                                                                        <p className='mb-0 fs-14 fw-600'>{lastCommunication.title}</p>
                                                                    </div>
                                                                    <div style={{ backgroundColor: 'white', overflowY: 'hidden', maxHeight: 'calc(100% - 75px)', height: 'calc(100% - 75px)' }} className='pb-2 pt-2'> {/* borderLeft: '4px solid #F0F0F0', borderRight: '4px solid #F0F0F0 */}
                                                                        <Row style={{ overflowY: 'hidden', maxHeight: '100%', height: '100%' }}>

                                                                            <Col md={11} className='ps-0 pe-0'>
                                                                                <div dangerouslySetInnerHTML={{ __html: lastCommunication.content }} />
                                                                            </Col>
                                                                            <Col md={1} className='align-center-vertically' style={{ justifyContent: 'flex-end', height: '100%' }}>
                                                                                <Link to={'/comunicaciones'} ><FontAwesomeIcon className='prev-next-icon greyHover' icon={faChevronRight} /></Link>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className='p-0' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }}>
                                                                        <Row style={{ width: '100%', backgroundColor: '#F0F0F0' }} className='ps-3 pe-3 pt-2 pb-2'>
                                                                            <Col md={12} className='ps-0 pe-0'>
                                                                                <div className='align-center-vertically' style={{ justifyContent: 'space-between' }}>
                                                                                    {lastCommunication.User && <div className='align-center-vertically me-2 fs-11'>
                                                                                        <div className='fitContentWidth'>
                                                                                            <div className='studentImg circle-container me-2' style={{ borderColor: 'white', width: '24px', height: '24px' }}>
                                                                                                <img className='' src={lastCommunication.User.avatarFile} alt={lastCommunication.User.name} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {lastCommunication.User.surname ? lastCommunication.User.surname.toUpperCase() : ""}, {lastCommunication.User.name}
                                                                                    </div>}
                                                                                    <p className='smallFontSize mb-0'>{lastCommunication.sendDate && `${moment(lastCommunication.sendDate).format("DD/MM/YY HH:mm")} hs`} </p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Row>

                                                            </Card>}

                                                </div>
                                            </Row>
                                        </Card>
                                    </Col>
                                }
                            </Row>}
                        </Col>
                    </Row >
                </div>

                 {/* Vista mobile ------------------------------------------------------------------------*/}
                <div className="d-block d-lg-none">
                    <Row className='mt-3'>
                        <Col md={12} xs={12} className='ps-0 pe-0'>
                        {myUserRole == 'Familia' || myUserRole == 'Padre-Madre-Tutor' ? <Dropdown className='mb-3'>
                            <Dropdown.Toggle className='dropdown-toggle-no-arrow' variant="" id="dropdown-basic" style={{ width: '100%', backgroundColor:'white', border:'1px solid rgba(0,0,0,.125)', borderRadius:'15px' }} >
                                <div className='align-center-vertically'>
                                    <img className='avatar-30 me-2' src={`${localStorage.getItem('familyMemberAvatarFile')}`} alt=""></img>
                                    <div style={{display:'flex', flexWrap:'wrap', columnGap:'8px', alignItems:'center'}}>
                                        {localStorage.getItem('familyMemberName')}
                                        <Badge bg='' style={{ backgroundColor: '#CFD4E3', color: 'black', fontWeight: '500', height:'min-content', marginBottom:'-2px' }} className=''>{localStorage.getItem('familyMemberGrade')}</Badge>
                                    </div> 
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='mb-2'>
                                {getFamilyMembersAmount() > 1 && <>
                                    <Dropdown.Item style={{ width: 197 }} as={Link} to={'/seleccionar-estudiante'}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 9 }} />Cambiar estudiante</Dropdown.Item>
                                </>}
                                <Dropdown.Item style={{ width: 197 }} as={Link} to={`/estudiante?id=${getFamilyMemberId()}`}><FontAwesomeIcon icon={faEye} style={{ marginRight: 9 }} />Ver estudiante</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> : ''}
                            <Row>
                                <Col className='ps-0 pe-0'>
                                    <Card className='p-0 cards overflow-hidden'  >
                                        <Carousel prevIcon={customPrevIcon} nextIcon={customNextIcon}>
                                            {newsItems.length == 0 ?
                                                <div style={{ height: '30vh', minHeight: '230px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Loading animation={'border'} />
                                                </div> :
                                                newsItems.map((element, i) => {
                                                    return (<Carousel.Item key={i} interval={3000} style={{ backgroundColor: element.colorBack }} >
                                                        <div className='justify-content-center' >
                                                            <img style={{ height: '30vh' }} src={element.img} />
                                                        </div>
                                                    </Carousel.Item>)
                                                })}
                                        </Carousel>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mt-3' style={{ columnGap: '6px' }}>
                                <Col className='p-0' md={12}>
                                    <Card className='home-mini-cards cards ' >
                                        {myUserRole != 'Familia' && myUserRole != 'Padre-Madre-Tutor' ? <> <Row className='align-items-center mb-2'>

                                            <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFile}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Comunicados</span></Col>

                                            {/*<ActionButton iconFa={faPlus} func={handleShowComu} classExtra='me-2' />*/}
                                        </Row>
                                            <Row>
                                                <div className='pt-0 pb-0 ps-2 pe-2' style={{ maxHeight: 'calc(55vh - 203px)', height: 'calc(55vh - 203px)' }}>
                                                    {isLastCommLoading ? <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                        <Loading animation={'border'} />
                                                    </div> :
                                                        Object.keys(lastCommunication).length == 0 ?
                                                            <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                                <NotFound iconFa={faFileCircleExclamation} text='No se han encontrado comunicados' />
                                                            </div> :
                                                            <Card className='greyBackCard cards interCards pt-0 pb-0 pe-1 ps-1' style={{ maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
                                                                <Row className='communication-card p-0' style={{ background: '', borderRadius: 8, border: "1px solid #F0F0F0", position: 'relative', maxHeight: '100%', height: '100%' }} >
                                                                    <div className='p-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '34px', backgroundColor: '#F0F0F0' }}>
                                                                        <p className='mb-0 fs-14 fw-600'>{lastCommunication.title}</p>
                                                                    </div>
                                                                    <div style={{ backgroundColor: 'white', overflowY: 'hidden', maxHeight: 'calc(100% - 75px)', height: 'calc(100% - 75px)' }} className='pb-2 pt-2'> {/* borderLeft: '4px solid #F0F0F0', borderRight: '4px solid #F0F0F0 */}
                                                                        <Row style={{ overflowY: 'hidden', maxHeight: '100%', height: '100%' }}>

                                                                            <Col xs={11} md={11} className='ps-0 pe-0'>
                                                                                <div dangerouslySetInnerHTML={{ __html: lastCommunication.content }} />
                                                                            </Col>
                                                                            <Col xs={1} md={1} style={{ justifyContent: 'flex-end', height: '100%' }}>
                                                                                <div className='align-center-vertically' style={{ height: '100%' }}>
                                                                                    <Link  ><FontAwesomeIcon className='prev-next-icon greyHover' icon={faChevronRight} /></Link>
                                                                                </div>


                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                    <div className='p-0' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }}>
                                                                        <Row style={{ width: '100%', backgroundColor: '#F0F0F0' }} className='ps-3 pe-3 pt-2 pb-2'>
                                                                            <Col md={12} className='ps-0 pe-0'>
                                                                                <div className='align-center-vertically' style={{ justifyContent: 'space-between' }}>
                                                                                    {lastCommunication.User && <div className='align-center-vertically me-2 fs-11'>
                                                                                        <div className='fitContentWidth'>
                                                                                            <div className='studentImg circle-container me-2' style={{ borderColor: 'white', width: '24px', height: '24px' }}>
                                                                                                <img className='' src={lastCommunication.User.avatarFile} alt={lastCommunication.User.name} />
                                                                                            </div>
                                                                                        </div>
                                                                                        {lastCommunication.User.surname ? lastCommunication.User.surname.toUpperCase() : ""}, {lastCommunication.User.name}
                                                                                    </div>}
                                                                                    <p className='smallFontSize mb-0'>{lastCommunication.sendDate && `${moment(lastCommunication.sendDate).format("DD/MM/YY HH:mm")} hs`} </p>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Row>

                                                            </Card>}

                                                </div>
                                            </Row>
                                        </>
                                            :
                                            <>
                                                <Row className='align-items-center mb-3'>

                                                    <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faBell}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Notificaciones</span></Col>

                                                    {/*<ActionButton iconFa={faPlus} func={handleShowComu} classExtra='me-2' />*/}
                                                </Row>
                                                <Row>
                                                    <div className='pt-0 pb-0 ps-2 pe-2' style={{ maxHeight: 'calc(55vh - 203px)', height: 'calc(55vh - 203px)', overflowY: 'auto' }}>
                                                        {notifications.length == 0 ?
                                                            <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                                <NotFound iconFa={faBell} text='No tenés notificaciones' />
                                                            </div> :
                                                            notifications.map((r, i) => (
                                                                <Card className={`${!r.isSeen && 'greyBackCard'} cards interCards pt-1 pb-1 pe-1 ps-1 pointer notificationCard`} onClick={() => handkeClickNotification(r.action)}>
                                                                <Row className=' p-0' style={{ background: '', borderRadius: 8, /* border: "1px solid #F0F0F0"  */ }} >
                                                                    <Col className='pe-1'>
                                                                        <div className='fw-500 notificationTitle'>{r.title}</div>
                                                                    </Col>
                                                                    <Col className=' ps-0' style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                                        <div className='align-center-vertically'>
                                                                            <div className='smallFontSize' style={{ textAlign: 'right' }}>{formatDate(r.date)}</div>
                                                                            </div>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <div style={{ marginTop: '-2px' }}>{r.body}</div>
                                                                    </Col>
                                                                </Row>
                                                                {!r.isSeen && <div style={{ borderRadius: '50%', backgroundColor: '#0480FD', height: '6px', width: '6px', position: 'absolute', right: '6px', top: '6px' }}></div>}
                                                            </Card>))}

                                                    </div>
                                                </Row>
                                            </>
                                        }
                                    </Card>
                                    <div className='d-block d-lg-none mb-3'></div>
                                </Col>
                                <Col md={12} className='pe-0 ps-0'>
                                    <Card className='home-mini-cards cards ' >
                                        <Row className='align-items-center mb-2'>


                                            <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faCalendar}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Calendario</span></Col>

                                            {/*<ActionButton iconFa={faPlus} classExtra='me-2' func={handleShowCreateEventCalendar} />  Determinar link to */}
                                        </Row>
                                        <Row className=''>
                                            <Calendar />
                                        </Row>
                                    </Card>
                                    <div className='d-block d-lg-none mb-3'></div>
                                </Col>
                                <Col md={12} className='d-block d-lg-none ps-0 pe-0'>
                                    {myUserRole != null && <Row>
                                        {myUserRole != 'Familia' && myUserRole != 'Padre-Madre-Tutor' ? <Col className='p-0'>
                                            <Courses />
                                        </Col>
                                            :
                                            <Col className='p-0'>
                                                <div className='mb-4'>
                                                    {/* <Chat heigth={'h-45'} /> */}
                                                    <Card className='home-mini-cards cards h-45'>
                                                        <Row className='align-items-center mb-3'>
                                                            <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faMessage}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Mensajes</span></Col>
                                                        </Row>
                                                        <Row>
                                                            <div style={{ height: '35vh', justifyContent: 'center' }} className='align-center-vertically'>
                                                                <NotFound text={'Próximamente encontrarás aquí tus mensajes'} iconFa={faMessage} />
                                                            </div>
                                                        </Row>
                                                    </Card>
                                                </div>
                                                <Card className='home-mini-cards cards '>
                                                    <Row className='align-items-center mb-3'>

                                                        <Col className='mt-2'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFile}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Comunicados</span></Col>

                                                        {/*<ActionButton iconFa={faPlus} func={handleShowComu} classExtra='me-2' />*/}
                                                    </Row>
                                                    <Row>

                                                        <div className='pt-0 pb-0 ps-2 pe-2' style={{ maxHeight: 'calc(55vh - 203px)', height: 'calc(55vh - 203px)' }}>
                                                            {isLastCommLoading ? <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                                <Loading animation={'border'} />
                                                            </div> :
                                                                Object.keys(lastCommunication).length == 0 ?
                                                                    <div style={{ height: '90%', alignItems: 'center' }} className='justify-content-center'>
                                                                        <NotFound iconFa={faFileCircleExclamation} text='No se han encontrado comunicados' />
                                                                    </div> :

                                                                    <Card className='greyBackCard cards interCards pt-0 pb-0 pe-1 ps-1' style={{ maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
                                                                        <Row className='communication-card p-0' style={{ background: '', borderRadius: 8, border: "1px solid #F0F0F0", position: 'relative', maxHeight: '100%', height: '100%' }} >
                                                                            <div className='p-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '34px', backgroundColor: '#F0F0F0' }}>
                                                                                <p className='mb-0 fs-14 fw-600'>{lastCommunication.title}</p>
                                                                            </div>
                                                                            <div style={{ backgroundColor: 'white', overflowY: 'hidden', maxHeight: 'calc(100% - 75px)', height: 'calc(100% - 75px)' }} className='pb-2 pt-2'> {/* borderLeft: '4px solid #F0F0F0', borderRight: '4px solid #F0F0F0 */}
                                                                                <Row style={{ overflowY: 'hidden', maxHeight: '100%', height: '100%' }}>

                                                                                    <Col md={11} className='ps-0 pe-0'>
                                                                                        <div dangerouslySetInnerHTML={{ __html: lastCommunication.content }} />
                                                                                    </Col>
                                                                                    <Col md={1} className='align-center-vertically' style={{ justifyContent: 'flex-end', height: '100%' }}>
                                                                                        <Link to={'/comunicaciones'} ><FontAwesomeIcon className='prev-next-icon greyHover' icon={faChevronRight} /></Link>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                            <div className='p-0' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', width: '100%' }}>
                                                                                <Row style={{ width: '100%', backgroundColor: '#F0F0F0' }} className='ps-3 pe-3 pt-2 pb-2'>
                                                                                    <Col md={12} className='ps-0 pe-0'>
                                                                                        <div className='align-center-vertically' style={{ justifyContent: 'space-between' }}>
                                                                                            {lastCommunication.User && <div className='align-center-vertically me-2 fs-11'>
                                                                                                <div className='fitContentWidth'>
                                                                                                    <div className='studentImg circle-container me-2' style={{ borderColor: 'white', width: '24px', height: '24px' }}>
                                                                                                        <img className='' src={lastCommunication.User.avatarFile} alt={lastCommunication.User.name} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {lastCommunication.User.surname ? lastCommunication.User.surname.toUpperCase() : ""}, {lastCommunication.User.name}
                                                                                            </div>}
                                                                                            <p className='smallFontSize mb-0'>{lastCommunication.sendDate && `${moment(lastCommunication.sendDate).format("DD/MM/YY HH:mm")} hs`} </p>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        </Row>

                                                                    </Card>}

                                                        </div>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        }
                                    </Row>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div >


            <EventModal show={showCreateEventCalendar} handleClose={handleCloseCreateEventCalendar} />

            <Modal size='lg' show={showComu} onHide={handleCloseModalComu} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faFile} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo Comunicado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6} className="mb-2 align-center-vertically ps-0">
                            <div className='align-center-vertically mb-1'>
                                <div className='dropDownIcon'></div>
                                <Form.Control className='textInputGray fs-14 wi-300' id="" placeholder="Título" onChange={handleComuTitle} />
                            </div>
                        </Col>
                        <Col md={12} className="ps-0 pe-0" style={{ display: 'flex' }}>
                            <div className='align-center-vertically mb-2' style={{ width: '100%' }}>
                                <FontAwesomeIcon icon={faFileAlt} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control as='textarea' className='textInputGray fs-14' id="" placeholder="Descripción" onChange={handleComuDescrp} />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalComu}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14 me-2'><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}