import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboardTeacher, faCheck, faCheckSquare, faClipboardUser, faEllipsisV, faEnvelope, faEye, faFileExport, faGraduationCap, faHeading, faIdCard, faImage, faKey, faPen, faPlus, faPrint, faSearch, faSortNumericUp, faTimes, faTrash, faUser, faUserFriends, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { faLetterboxd } from '@fortawesome/free-brands-svg-icons'
import { DEL, GET, POST } from '../../../controllers/endpoints.controller'
import Loading from '../../general-components/Loading'
import InfoField from '../../general-components/InfoField'

function Users() {
    const PAGE_TITLE = "Usuarios";
    const PAGE_ICON = faUsers;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getUsers(false);
        getFamilyMemberTypes();
    }, []);

    //DropDown Course
    let optionsCourse = ['1°A', '1°B', '2°A', '2°B', '3°A', '3°B', '4°A', '4°B', '5°A', '5°B'];
    const [selectedCourseOption, setSelectedCourseOption] = useState('Curso');

    const handleSelect = (eventKey) => {
        setSelectedCourseOption(optionsCourse[eventKey]);
    };

    //Dropdown level
    const [optionsLevel, setOptionsLevel] = useState([
        { name: 'Jardin', id: 3 },
        { name: 'Primaria', id: 1 },
        { name: 'Secundaria', id: 2 }
    ]);

    const [selectedLevelOption, setSelectedLevelOption] = useState('');

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(eventKey);
    };

    //Modal
    const [showNewUser, setShowNewUser] = useState(false);

    const handleShowNewUser = () => {
        setSelectedEnableOption('')
        setSelectedRoleOption("Rol");
        getRoles();
        setShowNewUser(true)
    };

    const handleCloseModalNewUser = () => {
        setShowNewUser(false);
    }

    const [deleteUserId, setDeleteUserId] = useState(null);
    const [deleteUserName, setDeleteUserName] = useState("");

    /*   const handleShowConfirmDeleteModal = (id, name) => {
          setDeleteUserId(id);
          setDeleteUserName(name);
          setShowConfirmDeleteModal(true);
      }; */

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({ state: false, modalTitle: "Eliminar", modalContent: "", funct: "" });
    const [deleteElement, setDeleteElement] = useState(null);
    const [deleteElementId, setDeleteElementId] = useState(null);
    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (id, title, content, funct, element) => {
        setShowConfirmDeleteModal({
            state: true,
            modalTitle: title,
            modalContent: content,
            funct: () => funct(id),
        });
        setDeleteElement(element)
    };


    const [authMethods, setAuthMethods] = useState(['Correo y contraseña', 'Google']);
    const [selectedAuthMethodOption, setSelectedAuthMethodOption] = useState('Autenticación');
    const handleSelectAuthMethod = (eventKey) => {
        setSelectedAuthMethodOption(authMethods[eventKey]);
    };

    const [roles, setRoles] = useState([]);
    const [selectedRoleOption, setSelectedRoleOption] = useState('');
    const [selectedRoleId, setSelectedRoleId] = useState(null);
    const [selectedRoleLevelId, setSelectedRoleLevelId] = useState(null);
    const handleSelectRole = (eventKey) => {
        setSelectedRoleOption(roles[eventKey].name);
        setSelectedRoleId(roles[eventKey].id);
        setSelectedRoleLevelId(roles[eventKey].levelId);
    };

    const enableOptions = [{ name: 'Habilitado', value: true }, { name: 'Deshabilitado', value: false }]
    const [selectedEnableOption, setSelectedEnableOption] = useState('');
    const handleSelectEnable = (eventKey) => {
        setSelectedEnableOption(eventKey);
    };

    const [users, setUsers] = useState([]);
    const [isTableLoaded, setIsTableLoaded] = useState(false);

    const getUsers = async function (avatars) {
        const req = await GET(`/sso/users/${avatars}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setUsers(res);
            setIsTableLoaded(true)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getRoles = async function () {
        const req = await GET(`/sso/roles`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setRoles(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postUser = async function () {
        const body = {
            name: document.getElementById("textNewUserName").value,
            surname: document.getElementById("textNewUserSurname").value,
            email: document.getElementById("textNewUserEmail").value,
            roleId: selectedRoleId,
            levelId: selectedRoleLevelId,
            enabled: document.getElementById("checkNewUserEnabled").checked,
            isGoogle: selectedAuthMethodOption == "Google"
        };

        const req = await POST(`/sso/signup`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseModalNewUser();
            getUsers();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isLoadingRelatedPeople, setIsLoadingRelatedPeople] = useState(false);

    const [showFamilyModal, setShowFamilyModal] = useState(false);

    const handleCloseFamilyModal = () => setShowFamilyModal(false);
    const [selectedUserForFamily, setSelectedUserForFamily] = useState(null)
    const handleShowFamilyModal = (r) => {
        setIsLoadingRelatedPeople(true)
        getRelatedPeople(r.id)
        setSelectedUserForFamily(r)
        setUsersSearchFamilyModal([])
        setShowFamilyModal(true);
    };

    const [usersSearchFamilyModal, setUsersSearchFamilyModal] = useState([])
    const searchPossibleStudents = async function () {
        setIsLoadingSearch(true);
        const chain = document.getElementById('textSearch').value;
        let roles = ["Alumno", "Padre-Madre-Tutor", "Familia"];

        const req = await GET(`/sso/users/search/byRoles/${roles.join(",")}/${chain == "" ? 'null' : chain}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setIsLoadingSearch(false)
            setUsersSearchFamilyModal(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }

    }

    const [showConfirmNewFmemberModal, setShowConfirmNewFmemberModal] = useState(false);
    const [familyMemberToAdd, setFamilyMemberToAdd] = useState(null);
    const handleCloseConfirmNewFmemberModal = () => setShowConfirmNewFmemberModal(false);
    const handleConfirmNewFmemberModal = (r) => {
        setFamilyMemberToAdd(r);
        setShowConfirmNewFmemberModal(true);
    };

    const [familyMemberTypes, setFamilyMemberTypes] = useState([]);
    const getFamilyMemberTypes = async function () {
        const req = await GET(`/sso/families/members/types`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setFamilyMemberTypes(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [selectedFmemberType, setSelectedFmemberType] = useState(null);
    const handleSelectFmemberType = (i) => {
        setSelectedFmemberType(familyMemberTypes[i]);
    }

    const postFamilyMember = async function () {
        const body = {
            addUserId: familyMemberToAdd.id,
            fromUserId: selectedUserForFamily.id,
            typeId: selectedFmemberType.id,
        }

        const req = await POST(`/sso/families/member`, getUserToken(), body);
        const res = await req.json();
        if (req.status === 201) {
            getRelatedPeople(selectedUserForFamily.id);
            handleCloseConfirmNewFmemberModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [relatedPeople, setRelatedPeople] = useState(null);
    const getRelatedPeople = async function (id) {
        setIsLoadingRelatedPeople(true)
        const req = await GET(`/sso/families/members/byUserId/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            let usersRelated = [];
            for (let s of res) {
                let o = users.find((u) => u.id == s.userId);
                o.typeMember = familyMemberTypes.find((t) => t.id === s.typeId);
                o.memberId = s.id;
               
                usersRelated.push(o);
            }
            setIsLoadingRelatedPeople(false)
            setRelatedPeople(usersRelated);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteFamilyMember = async function (id) {
        const body = {
            memberId: id,
        }
        const req = await DEL(`/sso/families/member`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getRelatedPeople(selectedUserForFamily.id);
            handleCloseConfirmDeleteModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='endRow'>
                        <Button variant='primary' onClick={handleShowNewUser}><FontAwesomeIcon className='mr-10' icon={faPlus} />Nuevo usuario</Button>
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    {!isTableLoaded ? <div className='justify-content-center'>
                        <Loading animation={'border'} />
                    </div> : <>
                        <div>
                            <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={4} className='align-center-vertically-v2'>
                                    <div>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={4} className='align-center-vertically-v2' >
                                    <div style={{ textAlign: 'center' }}>
                                        Correo electrónico
                                    </div>
                                </Col>
                                <Col md={3} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Rol
                                    </div>
                                </Col>
                                <Col md={1} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {users.map((r, i) => (
                            <div key={i}>
                                <div className='d-none d-lg-block'>
                                    <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={4} className='p-1 align-center-vertically-v2'>
                                            <Link to={`/usuarios/detalle/?id=${r.id}`} className='studentLink link-no-deco'><div style={{ textAlign: 'center' }} className='align-center-vertically-v2'>
                                                <div className='align-center-vertically'>
                                                    {r.name} {r.surname}
                                                </div>
                                            </div></Link>
                                        </Col>
                                        <Col md={4} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {r.email}
                                            </div>
                                        </Col>
                                        <Col md={3} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }} className='pt-1 pb-1'>
                                                {r.Role ? r.Role.name : ""}
                                            </div>
                                        </Col>
                                        <Col md={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                <Link to={`/usuarios/detalle/?id=${r.id}`}>
                                                    <Button variant="secondary">
                                                        <span><FontAwesomeIcon icon={faEye} /></span>
                                                    </Button>
                                                </Link>
                                                <Dropdown className='ms-2'>
                                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r.id, "Deshabilitar usuario", `¿Deseas deshabilitar el usuario "${r.name} ${r.surname}"?`)}><FontAwesomeIcon icon={faTimes} style={{ marginRight: 10, marginLeft: 2 }} />Deshabilitar</Dropdown.Item>
                                                        {r.Role && (r.Role.name == "Padre-Madre-Tutor" || r.Role.name == "Familia") ?
                                                            <Dropdown.Item onClick={() => handleShowFamilyModal(r)}><FontAwesomeIcon icon={faUserGroup} style={{ marginRight: 10, marginLeft: 2 }} />Personas vinculadas</Dropdown.Item>
                                                            : ''
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </>}
                </Card>
            </div>

            <Modal size='lg' show={showNewUser} onHide={handleCloseModalNewUser} centered className='assitanceModal modalContainer' backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faUser} style={{ minWidht: '20px' }} /><Modal.Title>Nuevo Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pb-4'>
                    <Row>
                        <Col md={6}>
                            <div className='align-center-vertically mb-2'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Nombre" id="textNewUserName" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='align-center-vertically mb-2'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Apellido" id="textNewUserSurname" />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faEnvelope} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Correo electrónico" id="textNewUserEmail" />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faUser} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedRoleOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedRoleOption}>
                                        {roles.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectRole(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faKey} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedAuthMethodOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedAuthMethodOption}>
                                        {authMethods.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectAuthMethod(i)}>{opt}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={6} className='mt-3'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon height={30} icon={faCheckSquare} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Check type="switch" label="Habilitado" id="checkNewUserEnabled" defaultChecked={true} />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseModalNewUser}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postUser}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            {showConfirmDeleteModal && <div>
                <div style={{ opacity: 0.5, zIndex: 1050 }}></div>
                <Modal show={showConfirmDeleteModal.state} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" className="custom-backdrop" style={{ zIndex: 1051 }}>
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>{showConfirmDeleteModal.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-4 pb-4'>
                        <span className=''>{showConfirmDeleteModal.modalContent}</span>
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                        <Button variant="danger" className='fs-14 me-2' onClick={showConfirmDeleteModal.funct}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                    </Modal.Footer>
                </Modal>
            </div>}

            <Modal size='lg' show={showFamilyModal} onHide={setShowFamilyModal} centered backdrop="static" style={{ zIndex: 1048 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faUserGroup} style={{ minWidth: '20px' }} />
                    <Modal.Title>Personas vinculadas</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    {isLoadingRelatedPeople ? <div className='justify-content-center'>
                        <Loading animation={'border'} classExtra='loading-100' />
                    </div> :
                        <div className='mb-4'>
                            <Row className='pt-2 pb-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={4} className='align-center-vertically-v2'>
                                    <div>
                                        Personas vinculadas
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div style={{ textAlign: 'center' }}>
                                        Rol
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'></Col>
                            </Row>
                            {relatedPeople != null && familyMemberTypes != null && relatedPeople.map((r, i) => (
                                <div key={i}>
                                    <div className='d-none d-lg-block'>
                                        <Row className='mb-1 pb-1 ps-2 pe-2 pt-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={4} className='align-center-vertically-v2'>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <p className=' m-0'>
                                                        <p className='m-0'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</p>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={6} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.typeMember && r.typeMember.name}
                                                </div>
                                            </Col>
                                            <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                    <Button variant="danger" className='fs-14' onClick={() => handleShowConfirmDeleteModal(r.memberId, "Eliminar", `¿Deseas eliminar el usuario "${r.name} ${r.surname}" como persona vinculada?`, deleteFamilyMember)}>
                                                        <FontAwesomeIcon className='mr-10' icon={faTimes} />Eliminar
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))}
                        </div>}
                    <Row>
                        <div className='align-center-vertically ps-0'>
                            <Form.Control className='textInputGray fs-14 wi-300' placeholder="Buscar..." id="textSearch" onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    searchPossibleStudents()
                                }
                            }} />
                            <ActionButton iconFa={faSearch} title='' classExtra='ms-2' func={searchPossibleStudents} />
                        </div>
                    </Row>
                    
                        <div>
                            <Row className='mt-2 pt-2 pb-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={1} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        #
                                    </div>
                                </Col>
                                <Col md={9} className='align-center-vertically-v2'>
                                    <div>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2'></Col>
                            </Row>
                            {isLoadingSearch ?
                        <div className='justify-content-center'>
                            <Loading animation={'border'} classExtra='loading-100' />
                        </div> :
                            usersSearchFamilyModal.map((r, i) => (
                                <div>
                                    <div className='d-none d-lg-block'>
                                        <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {1 + i}
                                                </div>
                                            </Col>
                                            <Col md={9} className='align-center-vertically-v2'>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className='studentImg circle-container me-2' style={{ borderColor: 'white' }}>
                                                        <img src={r.avatarFile} alt={r.name} />
                                                    </div>
                                                    <p className='classesTime m-0'>
                                                        <p className='m-0'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</p>
                                                        <p className='m-0' style={{ fontSize: 11, color: 'gray' }}></p>
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                <div style={{ display: 'flex', justifyContent: 'end', minHeight: '34px' }}>
                                                    {relatedPeople.some(item => item.id == r.id) ? '' : <Button variant="primary" className='fs-14' onClick={() => handleConfirmNewFmemberModal(r)}>
                                                        <FontAwesomeIcon className='mr-10' icon={faCheck} />Añadir
                                                    </Button>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))}
                        </div>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseFamilyModal}>
                        <FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            {showConfirmNewFmemberModal && <>
                <div className="backdrop-dark" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1050 }}></div>
                <Modal show={showConfirmNewFmemberModal} onHide={handleCloseConfirmNewFmemberModal} centered backdrop="static" style={{ zIndex: 1051 }}>
                    <Modal.Header closeButton>
                        <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faUser} style={{ minWidth: '20px' }} />
                        <Modal.Title>Añadir persona vinculada</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-4 pb-4'>
                        {familyMemberToAdd != null && <InfoField title='Usuario' content={`${familyMemberToAdd.surname ? familyMemberToAdd.surname.toUpperCase() : ""}, ${familyMemberToAdd.name}`} />}
                        <div className='align-center-vertically mt-2'>
                            <FontAwesomeIcon icon={faUser} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedFmemberType != null ? selectedFmemberType.name : 'Rol'}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedFmemberType == null ? 'Rol' : selectedFmemberType.name}>
                                    {familyMemberTypes.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectFmemberType(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='p-2'>
                        <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmNewFmemberModal}>
                            <FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar
                        </Button>
                        <Button variant="primary" className='fs-14 me-2' disabled={selectedFmemberType == null} onClick={postFamilyMember}>
                            <FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>}
        </div>
    )
}

export default Users