import React, { useEffect, useRef, useState } from 'react';
import { Card, Row, Col, Button } from "react-bootstrap";
import { faChalkboardTeacher, faClipboardUser, faEye, faFile, faFileCircleExclamation, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotFound from '../../general-components/NotFound';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import { GET } from '../../../controllers/endpoints.controller';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Loading from '../../general-components/Loading';

function Courses() {
  let userCheck = false;
  let colorStyle = !userCheck ? 'course-icon red' : 'course-icon green';

  const [courseList, setCourseList] = useState([]);
  const [areCoursesLoaded, setAreCoursesLoaded] = useState(false)

  useEffect(() => {
    getClassrooms();
  }, [])

  //Para saber si son mis cursos o no
  const user = {
    course: ['2°A', '2°B'],
  };
  let colorCourse = (classSelect) => {
    const selectedClass = user.course.find((classi) => classi == classSelect.course);
    return selectedClass ? 'blueTasky-back whiteText' : '';
  }

  const interCardsRef = useRef(null);
  useEffect(() => {
    if (interCardsRef.current) {
      const container = interCardsRef.current;
      if (container.scrollHeight > container.clientHeight) {
        container.style.padding = '0px 5px 0px 10px'
      } else {
        container.style.padding = '0px 10px 0px 10px'
      }
    }
  }, [interCardsRef])

  const [myCourses, setMyCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  useEffect(() => {
    setMyCourses([]);
    setAllCourses([]);
    if (courseList != null) {
      const updatedAllCourses = courseList.map((content, i) => {
        const colorCourseClass = colorCourse(content);
        const card = (
          <Card key={i} className='cards interCards p-0 overflow-hidden' style={colorCourseClass === '' ? { backgroundColor: 'rgb(240, 240, 240)', border: 'none' } : { backgroundColor: 'white' }}>
            <div className={`sideColor ${colorCourseClass}`} style={{ width: '10px' }}></div>
            <Row className='m-0 ms-2'>
              <Col className='course-col p-0 ms-2'>
                <p className='fs-16'>{content.degree}º{content.division}</p>
              </Col>
              <Col style={{ marginBottom: '5px', maxWidth: '73%' }}>
                <p className='mt-1'>{content.title}</p>
                <p className='smallFontSize'>Prof. {content.teacher} | {content.students} a.</p>
              </Col>
              <Col className='iconCol'>
                <FontAwesomeIcon className={colorStyle} icon={faClipboardUser}></FontAwesomeIcon>
              </Col>
            </Row>
          </Card>
        );
        if (colorCourseClass !== '') {
          setMyCourses((prevState) => ([...prevState, card]));
        }
        return card;
      });
      setAllCourses(updatedAllCourses);
    }
  }, [courseList]);

  const [allCoursesVerifier, setAll] = useState(false)
  let [buttonStyleAll, setButtonStyleAll] = useState('');
  let [buttonStyleMine, setButtonStyleMine] = useState('blueTasky-back whiteText');
  const handleAll = () => {
    setAll(true);
    setButtonStyleAll('blueTasky-back whiteText')
    setButtonStyleMine('')
  }
  const handleMine = () => {
    setAll(false);
    setButtonStyleAll('')
    setButtonStyleMine('blueTasky-back whiteText')
  }

  const getClassrooms = async function () {
    if (getUserRole() == "Dirección Jardín") {
      setAreCoursesLoaded(true);
    } else if (getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
      const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
      const res = await req.json();

      if (req.status === 200) {
        console.log(res)
        setCourseList(res);
        setAreCoursesLoaded(true)
      } else if (req.status === 403) {
        logout();
      } else {
        alert(res.message);
        setAreCoursesLoaded(true)
      }
    } else if (getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
      const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
      const res = await req.json();

      if (req.status === 200) {
          console.log(res)
          console.log(res)
        setCourseList(res);
        setAreCoursesLoaded(true)
      } else if (req.status === 403) {
          logout();
      } else {
          alert(res.message);
          setAreCoursesLoaded(true)
      }
  } else {
      const req = await GET(`/classes/classrooms/bySchoolYear/${2024}`, getUserToken());
      const res = await req.json();

      if (req.status === 200) {
          console.log(res)
          console.log(res)
        setCourseList(res);
        setAreCoursesLoaded(true)
      } else if (req.status === 403) {
          logout();
      } else {
          alert(res.message);
          setAreCoursesLoaded(true)
      }
  }
  }

  return (
    <Card className='home-mini-cards cards'>
      <Row className='align-items-center mb-3 '>
        <Col><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faFile}></FontAwesomeIcon></span><span className='ms-2 fs-16'>Cursos</span></Col>

        {/* <Col className='endRow'>
          <div className='switchButtonContainer'>
            <button className={buttonStyleMine + ' switchButton'} onClick={handleMine}>Todos</button>
            <span><button className={buttonStyleAll + ' switchButton'} onClick={handleAll}>Míos</button></span>
          </div>
        </Col> */}
      </Row>
      <Row>
        <div className='slideContent ' style={{ height: 'calc(100vh - 175px)' }} ref={interCardsRef}>
          {/*           {allCourses.length === 0 ? <NotFound iconFa={faFileCircleExclamation} text='Próximamente podrás ver tus cursos aquí' /> :
            !allCoursesVerifier ? allCourses : myCourses.length === 0 ? <NotFound iconFa={faFileCircleExclamation} text='No se han encontrado tus cursos' /> : myCourses
          } */}
          {areCoursesLoaded ? (allCourses.length === 0 ? 
          <div style={{height: '90%', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <NotFound iconFa={faFileCircleExclamation} text='Próximamente podrás ver tus cursos aquí' />
          </div>
           :
            courseList.map((content, i) => (
              <Row key={i} className='mb-1 ps-1 pe-1 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8, flexWrap: 'nowrap' }}>
                <Col className='align-center-vertically pe-0' style={{ maxWidth: '60px' }} >
                  <div style={{ textAlign: 'left' }} >
                    {content.degree}º {content.division}
                  </div>
                </Col>
                <Col className='align-center-vertically'>
                  <div style={{ textAlign: 'left' }} className='fs-12'>
                    {content.ClassroomStudents && content.ClassroomStudents.length + ' alumnos'}
                  </div>
                </Col>
                <Col className='endRow align-center-vertically'>
                  <div className='d-none d-lg-block'>
                    <Link to={`/cursos/?id=${content.id}`} style={{ textDecoration: 'none' }}>
                      <Button variant="outline-secondary" style={{ height: 'min-content' }} className='me-2'>
                        <span><FontAwesomeIcon icon={faEye} /></span>
                      </Button>
                    </Link>

                  </div>
                  <div >
                    <Link to={`/asistencia-curso/?id=${content.id}`} style={{ textDecoration: 'none' }}><Button variant="secondary" style={{ height: 'min-content' }}>
                      <span><FontAwesomeIcon icon={faClipboardUser} /></span>
                    </Button></Link>
                  </div>
                </Col>
              </Row>
            ))/*  : myCourses.length === 0 ? <NotFound iconFa={faFileCircleExclamation} text='No se han encontrado tus cursos' /> : myCourses */
          ) : <div className="justify-content-center">
            <Loading animation={'border'} classExtra='loading-100' />
          </div>}
        </div>
      </Row>
    </Card>
  )
}

export default Courses