import { jwtDecode } from "jwt-decode";
import { GET } from "./endpoints.controller";
import { formatName } from "../tools/tools";

export const saveUserData = function (user){
    localStorage.setItem('userToken', user.token);
    localStorage.setItem('userName', user.name);
    localStorage.setItem('userSurname', user.surname);

    let avatarFile = null;

    if(user.avatarFile){
        if(user.avatarFile.file){
            avatarFile = user.avatarFile.file
        }else{
            avatarFile = user.avatarFile
        }
    }

    if(user.mainId){
        localStorage.setItem('userMainId', user.mainId);
    }else{
        localStorage.setItem('userMainId', user.id);
    }

    localStorage.setItem('userAvatar', avatarFile);
    localStorage.setItem('userRole', user.Role.name);
    localStorage.setItem('userId', user.id);
    localStorage.setItem('userHasChilds', user.hasChilds ? user.hasChilds : false);
}

export const validateUser = function (){
    const token = localStorage.getItem("userToken");
    if(token !== undefined && token !== null){
        if(token.length < 10){
            logout();
        }else{
            window.location.href = "/inicio"
        }
    }else{
        logout();
    }
}

export const getChilds = async function () {
    const req = await GET('/sso/childs/mine', getUserToken());
    const res = await req.json();

    if (req.status === 200) {
        if(res.length == 1){
            if(getUserRole() == 'Familia' || getUserRole() == 'Padre-Madre-Tutor'){
                getMyStudents();
            }else{
                window.location.href = "/inicio";           
            }
        }else if(res.length > 1){
            window.location.href = "/seleccionar-rol";
        }
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}

export const validateToken = function (){
    const token = localStorage.getItem("userToken");

    if(token !== undefined && token !== null){
        const decoded = jwtDecode(token);
        try{
            if(decoded.user.id == null){
                logout();
            }
        }catch(e){
            logout();
        }
    }else{
        logout();
    }    
}

export const getUserToken = function (){
    return localStorage.getItem('userToken');
}

export const getUserRole = function (){
    return localStorage.getItem('userRole');
}

export const getUserId = function (){
    return localStorage.getItem('userId');
}

export const getUserMainId = function (){
    return localStorage.getItem('userMainId');
}

export const getHasChilds = function (){
    return localStorage.getItem('userHasChilds');
}

export const logout = function (){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('familyMemberId');
    localStorage.removeItem('familyMemberName');
    localStorage.removeItem('familyMemberSurname');
    localStorage.removeItem('familyMemberAvatarFile');
    localStorage.removeItem('familyMembersAmount');
    localStorage.removeItem('familyMemberToken');
    window.location.href = '/login';
}

export const forceLogout = function (){
    localStorage.removeItem('userToken');
    localStorage.removeItem('userName');
    localStorage.removeItem('userSurname');
    localStorage.removeItem('userAvatar');
    localStorage.removeItem('userRole');
    localStorage.removeItem('familyMemberId');
    localStorage.removeItem('familyMemberName');
    localStorage.removeItem('familyMemberSurname');
    localStorage.removeItem('familyMemberAvatarFile');
    localStorage.removeItem('familyMembersAmount');
    localStorage.removeItem('familyMemberToken');
    window.location.href = '/login';
}

export const saveAvatar = function (avatar){
    localStorage.setItem('userAvatar', avatar);
}

export const isLoggedIn = function (){
    return localStorage.getItem('userToken') != null;
}

export const getMyStudents = async function () {
    const req = await GET('/sso/families/members/children/mine', getUserToken());
    const res = await req.json();

    if (req.status === 200) {
        console.log(res)
        if(res.length == 1){
            localStorage.setItem('familyMemberId', res[0].id);
            localStorage.setItem('familyMemberName', res[0].name);
            localStorage.setItem('familyMemberSurname', res[0].surname);
            localStorage.setItem('familyMemberAvatarFile', res[0].avatarFile);
            localStorage.setItem('familyMembersAmount', res.length);
            saveChildToken(res[0].id);
        }else if(res.length > 1){
            window.location.href = "/seleccionar-estudiante";
        }else{
            window.location.href = "/no-habilitado";
        }
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}

export const saveChildToken = async function (userId){
    const req = await GET(`/sso/families/children/token/byUserId/${userId}`, getUserToken());
    const res = await req.json();

    if (req.status === 200) {
        console.log(res)
        localStorage.setItem('familyMemberToken', res.token);
        localStorage.setItem('familyMemberGrade', `${res.degree}º ${res.division}`);
        window.location.href = "/inicio";
    } else if (req.status === 403) {
        logout();
    } else {
        alert(res.message);
    }
}

export const getFamilyMemberId = function (){
    return localStorage.getItem('familyMemberId');
}

export const getFamilyMembersAmount = function (){
    return localStorage.getItem('familyMembersAmount');
}

export const getFamilyMemberToken = function (){
    return localStorage.getItem('familyMemberToken');
}