

const COLORS = {
    green: '#28A745',
    brand_primary: '#EDCD1E',
    brand_secondary: '#EDCD1E'
}

export const params = function () {
    const ENV = "PROD"

    switch(ENV){
        case "LOCAL":
            return ({
                apiUri: "http://localhost:8080/api",
                homePage: "https://tasky.digital/inicio",
                dataUri: "https://tasky.digital/data"
            });
        case "PROD":
            return ({
                apiUri: "https://tasky.digital:8080/api",
                homePage: "https://tasky.digital/inicio",
                dataUri: "https://tasky.digital/data"
            });
        case "TEST":
            return ({
                apiUri: "https://testing.tasky.digital:15001/api",
                homePage: "https://testing.tasky.digital/inicio",
                dataUri: "https://testing.tasky.digital/data"
            });
    }
}

export const colors = function () {
    return COLORS;
}