import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Dropdown, Form, Modal, Row, Spinner } from 'react-bootstrap'
import InfoField from '../../general-components/InfoField'
import Navbar from '../../navbar/Navbar';
import { faArrowAltCircleRight, faArrowCircleRight, faArrowLeft, faCalendar, faCheck, faComment, faCommentDots, faEllipsisV, faEnvelope, faEye, faEyeDropper, faEyeLowVision, faEyeSlash, faFaceDizzy, faFile, faFileArchive, faFileCircleCheck, faFrown, faGraduationCap, faHandshake, faHeading, faInfoCircle, faPen, faPhone, faPhoneAlt, faPlus, faTimes, faTrash, faTrashRestore, faTriangleExclamation, faUser, faUserGraduate, faUserGroup, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from '../../general-components/ActionButton';
import NotFound from '../../general-components/NotFound';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { DEL, GET, POST, PUT } from '../../../controllers/endpoints.controller';
import { getTextColorForBackground } from '../../../tools/tools';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Loading from '../../general-components/Loading';

function RegistrationDetail() {
    const PAGE_TITLE = "Detalle";
    const PAGE_ICON = faFileCircleCheck;
    const PREVIOUS_PAGE = "Inscripciones";
    const PREVIOUS_URL = "/inscripciones"

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getStatusType();
        getMeetingsType();
        getPotentialParent();
        getLevels();
    }, []);

    const [isTableLoaded, setIsTableLoaded] = useState(false);

    const getParams = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const [meetingTypes, setMeetingTypes] = useState([]);
    const [potentialParent, setPotentialParent] = useState(null);

    const [status, setstatus] = useState(null);
    const [selectedStatusOption, setSelectedStatusOption] = useState('Estado');
    const [selectedStatusId, setSelectedStatusId] = useState(null);

    const handleSelectStatus = (eventKey) => {
        setSelectedStatusOption(status[eventKey].name);
        setSelectedStatusId(status[eventKey].id);
    };

    const [meetingStatus, setMeetingStatus] = useState(['Pendiente', 'Realizada', 'Cancelada']);
    const [selectedMeetingStatus, setSelectedMeetingStatus] = useState('Pendiente');

    const handleSelectMeetingStatus = (eventKey) => {
        setSelectedMeetingStatus(meetingStatus[eventKey]);
    };

    const [levels, setLevels] = useState([]);
    const [selectedLevelOption, setSelectedLevelOption] = useState('Nivel');
    const [selectedLevelId, setSelectedLevelId] = useState(null);

    const handleSelectLevel = (eventKey) => {
        setSelectedLevelOption(levels[eventKey].name);
        setSelectedLevelId(levels[eventKey].id);
    };

    //Modal new meeting
    const [showNewInteraction, setShowNewInteraction] = useState(false);
    const handleShowNewInteraction = () => {
        setSelectedContacts([]);
        setSelectedMeetingTypeId(null);
        setSelectedMeetingTypeOption("Tipo de interacción");
        setSelectedDateDay(new Date());
        setIsEditingMeeting(false);
        setIsPostReady(-1);
        setShowNewInteraction(true);
    };
    const handleCloseNewInteraction = () => {
        setShowNewInteraction(false);
    }
    const [selectedDateDay, setSelectedDateDay] = useState(new Date());
    const handleChangeDate = date => {
        setSelectedDateDay(date);
    };

    const [selectedMeetingTypeOption, setSelectedMeetingTypeOption] = useState('Tipo de interacción');
    const [selectedMeetingTypeId, setSelectedMeetingTypeId] = useState(null);

    const handleSelectMeetingType = (eventKey) => {
        setSelectedMeetingTypeOption(meetingTypes[eventKey].name);
        setSelectedMeetingTypeId(meetingTypes[eventKey].id);

        if (meetingTypes[eventKey].name.includes("Entrevista")) {
            setSelectedMeetingStatus("Pendiente");
            setSelectedScheduledDate(null);
        } else {
            setSelectedMeetingStatus(null);
            setSelectedScheduledDate(null);
        }
    };

    const [selectedScheduledDate, setSelectedScheduledDate] = useState(null);
    const handleScheduledDate = date => {
        setSelectedScheduledDate(date);
    };

    //Funciones en buscar
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const handleSearchChange = (event) => {
        setSearchResults([]);
        searchUsers(["Preceptor Primaria", "Preceptor Secundaria", "Dirección Primaria", "Rectorado Secundaria", "Secretaría Secundaria", "Director Primaria", "Dirección Jardín"]);
    };

    const handleContactClick = (contact) => {
        if (!selectedContacts.find(selected => selected.id === contact.id)) {
            setSelectedContacts([...selectedContacts, contact]);
            document.getElementById('textSearch').value = '';
            setSearchResults([]);
        }
    };

    const removeContact = (contactToRemove) => {
        const updatedContacts = selectedContacts.filter(contact => contact.id !== contactToRemove.id);
        setSelectedContacts(updatedContacts);
    };

    const handleOutsideClick = (event) => {
        if (document.getElementById('textSearch').value != '') { }
        const clickedOutside = !event.target.closest('#textSearch');
        if (clickedOutside) {
            document.getElementById('textSearch').value = '';
        }
    }

    //Modal changeState
    const [showChangeStateModal, setShowChangeStateModal] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const handleShowChangeStateModal = (student) => {
        setSelectedStudent(student);
        setSelectedStatusOption(student.Status.name);
        setSelectedStatusId(student.Status.id);
        setShowChangeStateModal(true);
    };

    const handleCloseChangeStateModal = () => {
        setShowChangeStateModal(false);
    };

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deletemeetingId, setDeletemeetingId] = useState(null);
    const [deletemeetingName, setDeletemeetingName] = useState("");

    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (r) => {
        setDeletemeetingId(r.id);
        setDeletemeetingName(r.title.trim() != "" ? r.title : r.MeetingType.name);
        setShowConfirmDeleteModal(true);
    };

    const [isEditingMeeting, setIsEditingMeeting] = useState(false);
    const [editMeetingElement, setEditMeetingElement] = useState(null);
    const handleEditShowNewMeetingModal = (r) => {
        setIsPostReady(-1);
        setIsEditingMeeting(true);
        setEditMeetingElement(r);
        setSelectedDateDay(r.date);
        setSelectedMeetingTypeOption(r.MeetingType.name);
        setSelectedMeetingTypeId(r.MeetingType.id);
        setSelectedLevelId(r.levelId);
        setSelectedLevelOption(findLevelById(r.levelId));
        /* document.getElementById("nextStepNewMeeting").value = r.nextStep;
        document.getElementById("contentNewMeeting").value = r.content;
        document.getElementById("titleNewMeeting").value = r.title;
         */

        const scheduledDate = new Date(r.scheduledDate);
        setSelectedScheduledDate(scheduledDate);
        if (r.MeetingType.name.includes("Entrevista")) {
            if (!r.isCanceled && !r.isSuccessful) {
                setSelectedMeetingStatus("Pendiente")
            } else if (r.isSuccessful && !r.isCanceled) {
                setSelectedMeetingStatus("Realizada");
            } else if (!r.isSuccessful && r.isCanceled) {
                setSelectedMeetingStatus("Cancelada")
            }
        } else {
            setSelectedMeetingStatus(null);
        }
        setShowNewInteraction(true);
        //setSelectedContacts(r.MeetingHosts);
        let a = [];
        for (let hs of r.MeetingHosts) {
            let obj = {
                avatar: hs.avatar,
                avatarFile: hs.avatarFile,
                id: hs.userId,
                name: hs.name,
                surname: hs.surname
            }
            a.push(obj);
        }
        setSelectedContacts(a);
    };

    useEffect(() => {
        if (isEditingMeeting) {
            document.getElementById("nextStepNewMeeting").value = editMeetingElement.nextStep;
            document.getElementById("contentNewMeeting").value = editMeetingElement.content;
            document.getElementById("titleNewMeeting").value = editMeetingElement.title;
        }
    }, [showNewInteraction])

    const findLevelById = (id) => {
        return (levels.find(el => el.id == id)).name;
    }

    const [isPostReady, setIsPostReady] = useState(-1);

    const postModalNewMeeting = async function () {
        setIsPostReady(false);
        let hosts = [];
        for (let i = 0; i < selectedContacts.length; i++) {
            hosts.push(selectedContacts[i].id)
        }

        let isSuccessful = null;
        let isCanceled = null;

        switch (selectedMeetingStatus) {
            case "Pendiente":
                isSuccessful = false;
                isCanceled = false;
                break;
            case "Realizada":
                isSuccessful = true;
                isCanceled = false;
                break;
            case "Cancelada":
                isSuccessful = false;
                isCanceled = true;
                break;
        }

        const body = {
            date: selectedDateDay,
            typeId: selectedMeetingTypeId,
            parentId: getParams(),
            title: document.getElementById('titleNewMeeting').value,
            nextStep: document.getElementById('nextStepNewMeeting').value,
            content: document.getElementById('contentNewMeeting').value,
            isSuccessful: isSuccessful,
            isCanceled: isCanceled,
            scheduledDate: selectedScheduledDate,
            levelId: selectedLevelId,
            meetingHosts: hosts,
        }

        const req = await POST(`/registration/meeting`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewInteraction();
            getPotentialParent();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getStatusType = async function () {
        const req = await GET('/registration/statuses', getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setstatus(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getMeetingsType = async function () {
        const req = await GET('/registration/meeting', getUserToken());
        const res = await req.json();
        if (req.status === 200) {
            console.log(res)
            setMeetingTypes(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getPotentialParent = async function () {
        setIsTableLoaded(false);
        const req = await GET(`/registration/potentialCutstomerById/${getParams()}`, getUserToken());
        const res = await req.json();
        if (req.status === 200) {
            console.log(res);
            setPotentialParent(res);
            setIsTableLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteMeeting = async function (id) {
        const req = await DEL(`/registration/meeting/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getPotentialParent();
            handleCloseConfirmDeleteModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const searchUsers = async function (roles) {
        const chain = document.getElementById('textSearch').value;

        if (chain.length >= 3) {
            const req = await GET(`/sso/users/search/ByRoles/${roles.join(",")}/${chain}`, getUserToken());
            console.log(`/sso/users/search/ByRoles/${roles.join(",")}/${chain}`);
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setSearchResults(res)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            setSearchResults([]);
        }
    }

    const putModifiedStatus = async function () {
        const body = {
            studentId: selectedStudent.id,
            statusId: selectedStatusId
        }

        const req = await PUT(`/registration/updateStudentStatus/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getPotentialParent();
            handleCloseChangeStateModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const putIsSeen = async function () {
        const body = {
            parentId: potentialParent.id,
            isSeen: !potentialParent.isSeen
        }

        const req = await PUT(`/registration/isSeen`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getPotentialParent();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const processIcon = (icon) => {
        switch (icon) {
            case "PHON":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faPhone}></FontAwesomeIcon>;
            case "WHAT":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faWhatsapp}></FontAwesomeIcon>;
            case "EMAI":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faEnvelope}></FontAwesomeIcon>;
            case "MEET":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faUsers}></FontAwesomeIcon>;
            default:
                return <FontAwesomeIcon style={{ width: 10 }} icon={faInfoCircle}></FontAwesomeIcon>;
        }
    }

    const getLevels = async function () {
        const req = await GET(`/curriculum/levels/mine`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setLevels(res);
            for (let l of res) {
                if (l.mine == true) {
                    setSelectedLevelId(l.id);
                    setSelectedLevelOption(l.name);
                }
            }
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    ///registration/meeting/:id
    const putModifiedMeeting = async function () {
        setIsPostReady(false)
        let hosts = [];
        for (let i = 0; i < selectedContacts.length; i++) {
            hosts.push(selectedContacts[i].id)
        }
        let isSuccessful = null;
        let isCanceled = null;

        switch (selectedMeetingStatus) {
            case "Pendiente":
                isSuccessful = false;
                isCanceled = false;
                break;
            case "Realizada":
                isSuccessful = true;
                isCanceled = false;
                break;
            case "Cancelada":
                isSuccessful = false;
                isCanceled = true;
                break;
        }

        const body = {
            date: selectedDateDay,
            typeId: selectedMeetingTypeId,
            parentId: getParams(),
            title: document.getElementById('titleNewMeeting').value,
            nextStep: document.getElementById('nextStepNewMeeting').value,
            content: document.getElementById('contentNewMeeting').value,
            isSuccessful: isSuccessful,
            isCanceled: isCanceled,
            scheduledDate: selectedScheduledDate,
            levelId: selectedLevelId,
            meetingHosts: hosts,
        }

        const req = await PUT(`/registration/meeting/${editMeetingElement.id}`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseNewInteraction();
            getPotentialParent();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link className='link-primary' onClick={goBack}>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}
                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} - ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                {isTableLoaded ? <>

                    <Row className='mt-4'>
                        <Col className='endRow'>
                            <ActionButton title={potentialParent.isSeen ? "Marcar como no visto" : "Marcar como visto"} disabled={!isTableLoaded} func={putIsSeen} iconFa={faEye}></ActionButton>
                        </Col>
                    </Row>
                    <Card className='cards mt-3'>
                        {potentialParent != null && <InfoField title='Estado de inscripción' content={potentialParent.status} extraClass=''></InfoField>}
                        {potentialParent != null && <InfoField title='Fecha solicitud de inscripción' content={moment(potentialParent.createdAt).format("DD/MM/YYYY HH:mm")} extraClass='mt-2'></InfoField>}
                        {potentialParent != null && <InfoField title='Visto' content={potentialParent.isSeen ? "Si" : "No"} extraClass='mt-2'></InfoField>}
                    </Card>

                    <Card className='cards mt-3'>
                        <Row className='mb-3'>
                            <Col className='endRow'>
                                <ActionButton title='Nueva interacción' func={handleShowNewInteraction} iconFa={faPlus}></ActionButton>
                            </Col>
                        </Row>
                        {potentialParent && potentialParent.Meetings.length == 0 ? <NotFound text={'No se han encontrado interacciones'} iconFa={faPhone} classExtra={'mt-5 mb-5'} /> : <>
                            <Row className='mb-2 p-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={1} className='align-center-vertically'>
                                    <p className='mb-0 ps-2'>Fecha</p>
                                </Col>
                                <Col md={4} className='align-center-vertically' style={{ justifyContent: 'left' }}>
                                    <p className='mb-0 ps-2'>Descripción</p>
                                </Col>
                                <Col md={3} className='align-center-vertically' style={{ justifyContent: 'left' }}>
                                    <p className='mb-0 ps-2'>Próximo paso</p>
                                </Col>
                                <Col md={3} className='align-center-vertically' style={{ justifyContent: 'left' }}>
                                    <p className='mb-0 ps-2'>Participantes</p>
                                </Col>
                                <Col md={1} className='align-center-vertically'>
                                    <p className='mb-0 ps-2'></p>
                                </Col>
                            </Row>
                            {potentialParent != null && potentialParent.Meetings.map((r, i) => (
                                <Row key={i} className='mb-1 pt-2 pb-2 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={1} className='align-center-vertically'>
                                        <p className='mb-0 ps-2'>{moment(r.date).format("DD/MM/YY")}</p>
                                    </Col>
                                    <Col md={4} className='align-center-vertically' style={{ justifyContent: 'left' }}>
                                        <div>
                                            <p className={`ps-2 ${(r.content != null && r.content.length > 0) ? 'mb-2' : 'mb-0'}`} style={{ display: 'flex', rowGap: '3.5px', columnGap: '3.5px', flexWrap: 'wrap' }}>
                                                <Badge bg='' style={{ paddingTop: 1, paddingBottom: 3, fontWeight: 400, background: r.MeetingType.color, color: getTextColorForBackground(r.MeetingType.color) }} className={`${(r.content != null && r.content.length == 0) ? 'statusBadge fs-14' : ''} ${(r.isCanceled != null || r.isSuccessful != null) ? '' : ''}`}>{processIcon(r.MeetingType.icon)} {r.MeetingType.name}</Badge>
                                                {(r.isCanceled != null || r.isSuccessful != null) ?
                                                    <>
                                                        {(r.isCanceled == false && r.isSuccessful == false) ? <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }} className={` ${(r.content != null && r.content.length == 0) ? 'statusBadge fs-14' : ''}`}>Pendiente {r.scheduledDate ? `(${moment(r.scheduledDate).format('DD/MM/YY HH:mm')})` : ''}</Badge> : <></>}
                                                        {(r.isCanceled == true) ? <Badge bg='danger' style={{ fontWeight: 400 }} className={` ${(r.content != null && r.content.length == 0) ? 'statusBadge fs-14' : ''}`}>Cancelada {r.scheduledDate ? `(${moment(r.scheduledDate).format('DD/MM/YY HH:mm')})` : ''}</Badge> : <></>}
                                                        {(r.isSuccessful == true) ? <Badge bg='success' style={{ fontWeight: 400 }} className={` ${(r.content != null && r.content.length == 0) ? 'statusBadge fs-14' : ''}`}>Realizada {r.scheduledDate ? `(${moment(r.scheduledDate).format('DD/MM/YY HH:mm')})` : ''}</Badge> : <></>}
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </p>
                                            <p className='mb-0 ps-2 fw-700'>{r.title}</p>
                                            <p className='mb-0 ps-2'>{r.content}</p>
                                        </div>
                                    </Col>

                                    <Col md={3} className='align-center-vertically' style={{ justifyContent: 'left' }}>
                                        <p className='mb-0 ps-2'>{r.nextStep}</p>
                                    </Col>
                                    <Col md={3} className='align-center-vertically' style={{ justifyContent: 'left' }}>
                                        <div>
                                            {r.MeetingHosts.map((mh, j) => (
                                                <p className='m-0' key={j}>
                                                    <div className='align-center-vertically'>
                                                        <div className='fitContentWidth'>
                                                            <div className='studentImg circle-container me-2' style={{ borderColor: 'white' }}>
                                                                <img className='' src={mh.avatarFile} alt={mh.name} />
                                                            </div>
                                                        </div>
                                                        {mh.surname ? mh.surname.toUpperCase() : ""}, {mh.name}
                                                    </div>
                                                </p>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically endRow'>
                                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleEditShowNewMeetingModal(r)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Editar</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </>}
                    </Card>

                    <Card className='cards mt-3 '>
                        <Row className='align-items-center mb-3'>
                            <Col className='ps-0'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faUser}></FontAwesomeIcon></span><span className='ps-2 fs-16'>Datos del adulto responsable</span></Col>
                        </Row>
                        <InfoField title='Nombre' content={potentialParent != null && (potentialParent.name + ' ' + potentialParent.surname)} extraClass='mb-2'></InfoField>
                        {potentialParent != null && potentialParent.ParentData.map((r, i) => (
                            <InfoField key={i} title={r.param} content={r.value} extraClass={i == (potentialParent.ParentData.length - 1) ? '' : 'mb-2'}></InfoField>
                        ))}
                    </Card>

                    {potentialParent != null && potentialParent.PotentialStudents.map((r, i) => (
                        <Card className='cards mt-3 ' key={i}>
                            <Row className='align-items-center mb-3'>
                                <Col className='ps-0'><span className={`navbar-icon`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={faUserGraduate}></FontAwesomeIcon></span><span className='ps-2 fs-16'>Datos del alumno/a</span></Col>
                                <Col className='endRow'>
                                    <ActionButton title='Modificar estado' func={() => handleShowChangeStateModal(r)} iconFa={faPen}></ActionButton>
                                </Col>
                            </Row>
                            <InfoField title='Nombre' content={r.name + ' ' + r.surname} extraClass='mb-2'></InfoField>
                            <InfoField title='Estado' content={<Badge bg='' style={{ background: `${r.Status.color}`, color: `${getTextColorForBackground(r.Status.color)}` }} className='statusBadge fs-14'>{r.Status.name}</Badge>} extraClass='mb-2'></InfoField>
                            {r.StudentData.map((s, j) => (
                                <InfoField key={j} title={s.param} content={s.value} extraClass={j == (r.StudentData.length - 1) ? '' : 'mb-2'}></InfoField>
                            ))}
                        </Card>
                    ))}
                </> :
                    <Card className='cards mt-3 '>
                        <div className='justify-content-center' style={{ alignItems: 'center' }}>
                            <Loading animation={'border'} classExtra='loading-100' />
                        </div>
                    </Card>}
            </div>

            <Modal onClick={handleOutsideClick} size='lg' show={showNewInteraction} onHide={handleCloseNewInteraction} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faHandshake} style={{ minWidht: '20px' }} /><Modal.Title>{isEditingMeeting ? 'Ediar interacción' : 'Nueva interacción'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14 pt-4'>
                    <Row >
                        <Col className='mb-2'>
                            <div className='align-center-vertically'>
                                <div className='dropDownIcon' ></div>
                                <Form.Control className='textInputGray fs-14 wi-300' placeholder="Título" id="titleNewMeeting" />
                            </div>
                        </Col>
                        <Col className='mb-2'>
                            <div className='align-center-vertically w-300'>
                                <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                <DatePicker
                                    selected={selectedDateDay}
                                    onChange={handleChangeDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Fecha"
                                    className='dropdown-toggle wi-300'
                                    locale='es' maxDate={moment().toDate()} />
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically '>
                                <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedMeetingTypeOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedMeetingTypeOption}>
                                        {meetingTypes.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectMeetingType(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={6} className='mb-2'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faGraduationCap} className='dropDownIcon' />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedLevelOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedLevelOption}>
                                        {levels.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectLevel(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={12} className='mb-2'>
                            <div className='align-center-vertically' style={{ width: '100%' }}>
                                <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control as='textarea' className='textInputGray fs-14' id="contentNewMeeting" placeholder="Descripción" style={{ maxWidth: '680px' }} />
                            </div>
                        </Col>
                        <Col className='mb-2' md={12}>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faArrowCircleRight} className='dropDownIcon' style={{ height: 16 }} />
                                <Form.Control className='textInputGray fs-14' placeholder="Próximo paso" id="nextStepNewMeeting" style={{ maxWidth: '680px' }} />
                            </div>
                        </Col>
                        {
                            (selectedMeetingTypeOption.includes("Entrevista")) ?
                                <>
                                    <Col className='mb-2' md={6}>
                                        <div className='align-center-vertically'>
                                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                                            <DatePicker
                                                selected={selectedScheduledDate}
                                                onChange={handleScheduledDate}
                                                showTimeSelect
                                                timeIntervals={15}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                placeholderText="Fecha de reunión"
                                                className='dropdown-toggle wi-300'
                                                locale='es' />
                                        </div>
                                    </Col>
                                    <Col className='mb-2' md={6}>
                                        <div className='align-center-vertically'>
                                            <FontAwesomeIcon icon={faCheck} className='dropDownIcon' style={{ height: 16 }} />
                                            <Dropdown className='dropdownButton'>
                                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                                    {selectedMeetingStatus}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="fs-14" title={selectedMeetingStatus}>
                                                    {meetingStatus.map((opt, i) => (
                                                        <Dropdown.Item key={i} onClick={() => handleSelectMeetingStatus(i)}>{opt}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col></>
                                :
                                <></>
                        }

                        <Col className='' md={6}>
                            <div className='align-center-vertically '>
                                <FontAwesomeIcon icon={faUserGroup} className='dropDownIcon' style={{ height: 16 }} />
                                <div>
                                    <Form.Control className='textInputGray fs-14 wi-300' autoComplete="off" id="textSearch" placeholder="Agregar participantes..." onChange={handleSearchChange} />
                                    <div className='search-results' style={{ display: searchResults.length > 0 ? 'block' : 'none' }}>
                                        {searchResults && searchResults.map((contact, index) => (
                                            <div key={index} onClick={() => handleContactClick(contact)} style={{ cursor: 'pointer' }}>
                                                {contact.name} {contact.surname}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='ms-1 align-center-vertically mb-3' md={12}>
                            <div className='dropDownIcon' ></div>
                            <div className='align-center-vertically  wrapCont'>
                                {selectedContacts.map((contact, index) => (
                                    <div key={index} className='ms-2 userSelectedModal mt-2' style={{ display: 'inline-block' }}>
                                        <span>{contact.name} {contact.surname}</span>
                                        <FontAwesomeIcon onClick={() => removeContact(contact)} icon={faTimes} className='remiderCross ms-2' />
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewInteraction}><FontAwesomeIcon className='mr-10' icon={faTimes} onClick={handleCloseNewInteraction} />Cerrar</Button>
                    <Button variant="primary" onClick={isEditingMeeting ? putModifiedMeeting : postModalNewMeeting} className='fs-14' disabled={(selectedMeetingTypeId == null || selectedLevelId == null || isPostReady != -1 || (selectedMeetingTypeOption.includes("Entrevista") && selectedScheduledDate==null))}>{isPostReady == -1 ? <><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</> : <div className="justify-content-center" style={{ width: '85px' }}>
                        <Loading animation={'grow'} size='20px' bsSize='sm' variant='light' classExtra='justify-content-center' />
                    </div>}</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showChangeStateModal} onHide={handleCloseChangeStateModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faPen} style={{ minWidht: '20px' }} /><Modal.Title>Modificar estado de inscripción</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InfoField title='Nombre' content={selectedStudent && (selectedStudent.name + ' ' + selectedStudent.surname)} extraClass='mb-4'></InfoField>
                    <Row className='mb-4'>
                        <Col>
                            <div className='align-center-vertically '>
                                <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedStatusOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedStatusOption}>
                                        {status != null && status.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectStatus(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseChangeStateModal}><FontAwesomeIcon className='mr-10' icon={faTimes} onClick={handleCloseNewInteraction} />Cerrar</Button>
                    <Button variant="primary" className='fs-14' onClick={putModifiedStatus}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas eliminar "{deletemeetingName}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={() => deleteMeeting(deletemeetingId)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default RegistrationDetail