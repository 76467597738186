import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar';
import { Col, Dropdown, DropdownMenu, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChalkboardTeacher, faFile, faPen, faUser } from '@fortawesome/free-solid-svg-icons';
import ActionButton from '../../general-components/ActionButton';
import { validateToken } from '../../../controllers/user.controller';

function Report() {
    const PAGE_TITLE = "Boletín";
    const PAGE_ICON = faFile;

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();
    }, []);

    //DropDown Course
    let optionsCourse = ['1°A', '1°B', '1°A', '1°C', '1°A', '5°A', '1°A', '1°A', '1°A', '1°A'];
    const [selectedCourseOption, setSelectedCourseOption] = useState('Curso');

    const handleSelect = (eventKey) => {
        setSelectedCourseOption(optionsCourse[eventKey]);
    };

    //DropDown Alumno
    let optionsStudent = ['Valentina Francesch', 'Carolina', 'Mauro', 'Santiago', 'Julieta', 'Micaela'];
    const [selectedStudentOption, setSelectedStudentOption] = useState('Alumno');

    const handleSelectStudent = (eventKey) => {
        setSelectedStudentOption(optionsStudent[eventKey]);
    };

    //Dropdown periodo
    let optionsFrame = ['1°bimestre', '2°bimestre', '3°bimestre', '4°bimestre'];
    const [selectedFrameOption, setSelectedFrameOption] = useState('Periodo');

    const handleSelectFrame = (eventKey) => {
        setSelectedFrameOption(optionsFrame[eventKey]);
    };

    //Funcion l
    const download = () => {
        console.log("Descargar boletin")
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4 align-items-center marginSep'>
                    <Col className='fitContentWidth align-items-center'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedCourseOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedCourseOption}>
                                    {optionsCourse.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth align-items-center'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faUser} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedStudentOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedStudentOption}>
                                    {optionsStudent.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectStudent(i)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth align-items-center'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-150">
                                    {selectedFrameOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedFrameOption}>
                                    {optionsFrame.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectFrame(i)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        <ActionButton iconFa={faFile} title={'Descargar'} func={download} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Report