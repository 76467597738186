import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Badge, Card, Col, Dropdown, DropdownMenu, Row, Table, Button, Modal, Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookReader, faCalendar, faChalkboardTeacher, faCheck, faClipboardUser, faDownload, faEllipsisV, faEye, faFileCircleExclamation, faFileExport, faHandHoldingHeart, faHandsHelping, faPen, faPeopleCarry, faPlus, faPrint, faTimes, faTrash, faUniversalAccess, faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import ActionButton from '../../general-components/ActionButton'
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { GET, POST } from '../../../controllers/endpoints.controller'
import moment from 'moment'
import Loading from '../../general-components/Loading'
import InfoField from '../../general-components/InfoField'
import { downloadFile } from '../../../tools/tools'
import NotFound from '../../general-components/NotFound'

function Reports() {
    const PAGE_TITLE = "Boletín (entregable)";
    const PAGE_ICON = faChalkboardTeacher;

    const [isTableLoaded, setIsTableLoaded] = useState(false);

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getClassrooms();
    }, []);

    const [showLoading, setShowLoading] = React.useState(false);
    const handleCloseLoadingModal = () => { setShowLoading(false); };
    const handleShowLoadingModal = () => {
        setShowLoading(true);
    };

    //DropDown Course
    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);

    const handleSelectClassroom = (eventKey) => {
        setIsTableLoaded(false)
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id);
        setSelectedPeriodGeneralOption("Período");
        setSelectedPeriodGeneralId(null);
    };

    const [classrooms, setClassrooms] = useState([]);
    const [students, setStudents] = useState([]);

    const getClassrooms = async function () {
        if (getUserRole() == "Docente Primaria" || getUserRole() == "Docente Secundaria") {
            const req = await GET(`/classes/classrooms/byTeacherId`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }
                getPeriods(res[0].id)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            const req = await GET(`/classes/classrooms/bySchoolYear/${moment().format('YYYY')}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setClassrooms(res);
                if (res.length > 0) {
                    setSelectedClassroomOption(`${res[0].degree}º ${res[0].division}`);
                    setSelectedClassroomId(res[0].id)
                }
                getPeriods(res[0].id)
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getStudentsWithReportFiles = async function (classroomId, periodId) {
        const req = await GET(`/grades/reportFiles/byClassroomIdAndPeriodId/${classroomId}/${periodId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudents(res);
            setIsTableLoaded(true);
            checkReportStatus(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [periods, setPeriods] = useState([]);
    const getPeriods = async function (cid) {
        const req = await GET(`/grades/periods/byClassroomId/noGrouped/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setPeriods(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [selectedPeriodGeneralOption, setSelectedPeriodGeneralOption] = useState('Período');
    const [selectedPeriodGeneralId, setSelectedPeriodGeneralId] = useState(null);

    const handleSelectPeriodGeneral = (eventKey) => {
        setSelectedPeriodGeneralOption(periods[eventKey].name);
        setSelectedPeriodGeneralId(periods[eventKey].id);
        getStudentsWithReportFiles(selectedClassroomId, periods[eventKey].id)
    };

    const [areAllReportsFinished, setAreAllReportsFinished] = useState(false);
    let isReportPending = false;

    const [showConfirmcreateReportModal, setShowConfirmcreateReportModal] = useState(false);
    const handleCloseConfirmcreateReportModal = () => setShowConfirmcreateReportModal(false);
    const handleShowConfirmcreateReportModal = () => {
        setShowConfirmcreateReportModal(true);
    };

    const postCreateReports = async function () {
        const body = {
            classroomId: selectedClassroomId,
            periodId: selectedPeriodGeneralId
        }

        const req = await POST(`/grades/report/byClassroom`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            handleCloseConfirmcreateReportModal();
            getStudentsWithReportFiles(selectedClassroomId, selectedPeriodGeneralId)
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const checkReportStatus = (reports) => {
        let areAllFinished = true;
        for (let i = 0; i < reports.length; i++) {
            if (reports[i].isPending == true || reports[i].hasReportFile == false) {
                areAllFinished = false;
                break;
            }
        }
        setAreAllReportsFinished(areAllFinished);
    }

    const downloadAllReports = async function () {
        handleShowLoadingModal();
        const req = await GET(`/grades/reportFiles/zip/byClassroomIdAndPeriodId/${selectedClassroomId}/${selectedPeriodGeneralId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            const linkSource = res.file;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = `Boletines ${selectedClassroomOption}.${res.extension}`;
            downloadLink.click();
            handleCloseLoadingModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <span className={'navbar-icon'}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectClassroom(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='fitContentWidth'>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faCalendar} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-175">
                                    {selectedPeriodGeneralOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedPeriodGeneralOption}>
                                    {periods.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectPeriodGeneral(i)}>{opt.name}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        {areAllReportsFinished && <ActionButton iconFa={faDownload} func={downloadAllReports} title='Descargar todos' variant='secondary' classExtra='me-2' />}
                        <ActionButton iconFa={faPlus} title='Generar boletines' variant='primary' disabled={selectedPeriodGeneralId == null} func={handleShowConfirmcreateReportModal} />
                    </Col>
                </Row>

                <Card className='cards mt-3'>
                    {!isTableLoaded ?
                        <div className='justify-content-center'>
                            {selectedPeriodGeneralId != null && selectedClassroomId != null ?
                                <Loading animation={'border'} classExtra='loading-100' />
                                :
                                <NotFound classExtra="loading-100" iconFa={faFileCircleExclamation} text='Seleccioná un curso y un período' />
                            }
                        </div> : <><div>
                            <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={1} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        #
                                    </div>
                                </Col>
                                <Col md={5} className='align-center-vertically-v2'>
                                    <div>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={3} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                        Estado boletín
                                    </div>
                                </Col>
                                <Col md={3} className='align-center-vertically-v2'>
                                    <div style={{ textAlign: 'center' }}>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                            {students.map((r, i) => (
                                <div key={i}>
                                    <div className='d-none d-lg-block'>
                                        <Row className='mb-1 ps-2 pe-2 pt-1 pb-1' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                            <Col md={1} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {1 + i}
                                                </div>
                                            </Col>
                                            <Col md={5} className='align-center-vertically-v2'>
                                                {r.surname ? r.surname.toUpperCase() : ""}, {r.name}
                                            </Col>
                                            <Col md={3} className='align-center-vertically' style={{ justifyContent: 'center' }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.hasReportFile ?
                                                        <>
                                                            {r.isPending ?
                                                                <Badge bg='warning' className='statusBadge fs-14 wi-100'>En proceso</Badge>
                                                                :
                                                                <Badge bg='success' className='statusBadge fs-14 wi-100'>Listo</Badge>
                                                            }
                                                        </>
                                                        :
                                                        <Badge bg='secondary' className='statusBadge fs-14 wi-100'>Sin generar</Badge>
                                                    }
                                                </div>
                                            </Col>

                                            <Col md={3} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                {(r.hasReportFile && !r.isPending) ?
                                                    <Dropdown className='ms-2'>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => downloadFile(r.reportFileFileId, `Boletín ${selectedPeriodGeneralOption} - ${selectedClassroomOption} - ${r.surname ? r.surname.toUpperCase() : ""}, ${r.name}.pdf`)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 10, marginLeft: 2 }} />Descargar</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    :
                                                    <></>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ))}
                        </>}
                </Card>
            </div>

            <Modal show={showConfirmcreateReportModal} onHide={handleCloseConfirmcreateReportModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faFileExport} style={{ minWidht: '20px' }} /><Modal.Title>Generar boletines</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 mb-3'>
                    <span className=''>¿Deseas generar los boletines de "{selectedClassroomOption}" para el "{selectedPeriodGeneralOption}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmcreateReportModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14 me-2' onClick={postCreateReports}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showLoading} centered backdrop="static" keyboard={false} onHide={handleCloseLoadingModal}>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <Spinner className='mt-3' variant='primary' animation="border" />
                    <p className='mt-1 mb-3'>Procesando...</p>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Reports