import React, { useEffect, useState } from 'react'
import Navbar from '../../navbar/Navbar'
import { Card, Col, Dropdown, Row, Button, Modal, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChalkboardTeacher, faCheck, faClapperboard, faCog, faEllipsisV, faEye, faGraduationCap, faPlus, faTicket, faTimes, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { getUserRole, getUserToken, logout, validateToken } from '../../../controllers/user.controller'
import { useNavigate } from 'react-router-dom';
import { DEL, GET, POST } from '../../../controllers/endpoints.controller'
import ActionButton from '../../general-components/ActionButton'
import InfoField from '../../general-components/InfoField'
import Loading from '../../general-components/Loading'

function Classes() {
    const PAGE_TITLE = "Materias";
    const PAGE_ICON = faCog;
    const PREVIOUS_PAGE = "Configuración > Cursos";
    const PREVIOUS_URL = "/configuracion/cursos"

    const [courseId, setCourseId] = useState('Curso');

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1); // Navega hacia atrás en el historial
    };

    const getParam = function (name) { //'CourseId' and 'schoolYear'
        const params = new URLSearchParams(window.location.search);
        if (params.has(name)) {
            return params.get(name);
        } else {
            return null;
        }
    }

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getClassrooms();
        setSelectedClassroomId(getParam("curso"))
        getClassesByClassroomId(getParam("curso"));
    }, []);

    const [subjects, setSubjects] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [selectedClassId, setSelectedClassId] = useState(null);

    const [showAssignTeacherModal, setShowAssignTeacherModal] = useState(false);
    const handleShowAssignTeacherModal = (cid) => {
        setSelectedClassId(cid);
        getTeachers();
        setShowAssignTeacherModal(true);
    };

    const handleCloseAssignTeacherModal = () => {
        setShowAssignTeacherModal(false);
    }

    const [showNewClassModal, setShowNewClassModal] = useState(false);
    const handleShowNewClassModal = () => {
        getSubjectsByClassroomId(selectedClassroomId);
        setShowNewClassModal(true);
    };

    const handleCloseNewClassModal = () => {
        setShowNewClassModal(false);
    }

    const [selectedSubjectOption, setSelectedSubjectOption] = useState('Materia');
    const [selectedSubjectId, setSelectedSubjectId] = useState(null);

    const handleSelectSubject = (eventKey) => {
        setSelectedSubjectOption(subjects[eventKey].name);
        setSelectedSubjectId(subjects[eventKey].id);
    };

    const [selectedClassroomOption, setSelectedClassroomOption] = useState('Curso');
    const [selectedClassroomId, setSelectedClassroomId] = useState(null);

    const handleSelect = (eventKey) => {
        setSelectedClassroomOption(`${classrooms[eventKey].degree}º ${classrooms[eventKey].division}`);
        setSelectedClassroomId(classrooms[eventKey].id)
        getClassesByClassroomId(classrooms[eventKey].id)
    };

    const [classes, setClasses] = useState([]);
    const [classrooms, setClassrooms] = useState([]);

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState({ state: false, modalTitle: "Eliminar", modalContent: "", funct: "" });
    const [deleteElementId, setDeleteElementId] = useState(null);

    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal({ state: false, modalTitle: "Eliminar", modalContent: "", funct: "" });
    const handleShowConfirmDeleteModal = (id, title, content, funct) => {
        setDeleteElementId(id);
        setShowConfirmDeleteModal({
            state: true,
            modalTitle: title,
            modalContent: content,
            funct: () => funct(id),
        });
    };

    const getClassesByClassroomId = async function (cid) {
        const req = await GET(`/classes/classes/withTeachers/byClassroomId/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setClasses(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getClassrooms = async function () {
        const req = await GET(`/classes/classrooms/bySchoolYear/${getParam("cicloLectivo")}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setClassrooms(res);

            let actualClassroomId = getParam("curso");
            let actualClassroom = null
            let i = 0;

            while (actualClassroom == null && i < res.length) {
                if (res[i].id == actualClassroomId) {
                    actualClassroom = `${res[i].degree}º ${res[i].division}`
                }
                i++;
            }

            setSelectedClassroomOption(actualClassroom);

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getTeachers = async function () {
        let url;
        if (getUserRole().includes("Primaria")) {
            url = `/sso/users/search/ByRole/Docente Primaria/null`
        } else if (getUserRole().includes("Secundaria")) {
            url = `/sso/users/search/ByRole/Docente Secundaria/null`
        }
        const req = await GET(url, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setTeachers(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postAssignTeacher = async function (uid) {
        const body = {
            userId: uid,
            classId: selectedClassId
        }

        const req = await POST(`/classes/teacher`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getClassesByClassroomId(selectedClassroomId);
            handleCloseAssignTeacherModal();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getSubjectsByClassroomId = async function (cid) {
        const req = await GET(`/curriculum/subjects/byClassroomId/${cid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setSubjects(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const postClass = async function () {
        const body = {
            subjectId: selectedSubjectId,
            classroomId: selectedClassroomId
        }

        const req = await POST(`/classes/classes`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseNewClassModal();
            getClassesByClassroomId(selectedClassroomId);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteClass = async function () {
        const req = await DEL(`/class/${deleteElementId}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            handleCloseConfirmDeleteModal();
            getClassesByClassroomId(selectedClassroomId);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [studentsBySubSubject, setStudentsBySubSubject] = useState(null);
    const [areModalStudentsLoaded, setAreModalStudentsLoaded] = useState(false);
    const getStudentsBySubSubject = async function (cid, sid, ssid) {
        const req = await GET(`/classes/students/subSubject/byClassroomId&SubjectId&SubSubjectId/${cid}/${sid}/${ssid}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setStudentsBySubSubject(res);
            setAreModalStudentsLoaded(true);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const [showModifyStudentsListModal, setShowModifyStudentsListModal] = useState(false);
    const [subjectToModify, setSubjectToModify] = useState(null);

    const handleCloseModifyStudentsListModal = () => setShowModifyStudentsListModal(false);
    const handleShowModifyStudentsListModal = (s) => {
        setSubjectToModify(s);
        setAreModalStudentsLoaded(false);
        getStudentsBySubSubject(selectedClassroomId, s.subjectId, s.subSubjectId);
        setShowModifyStudentsListModal(true);
    };

    const postAddStudentToSubSubject = async function (studentId) {
        setAreModalStudentsLoaded(false)
        const body = {
            classId: subjectToModify.id,
            userId: studentId
        }

        const req = await POST(`/classes/class/student/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            getStudentsBySubSubject(selectedClassroomId, subjectToModify.subjectId, subjectToModify.subSubjectId);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteStudentFromSubSubject = async function (id) {
        const body = {
            classId: subjectToModify.id,
            userId: id
        }

        const req = await DEL(`/classes/class/student/`, getUserToken(), body);
        const res = await req.json();

        if (req.status === 201) {
            handleCloseConfirmDeleteModal();
            setAreModalStudentsLoaded(false);
            getStudentsBySubSubject(selectedClassroomId, subjectToModify.subjectId, subjectToModify.subSubjectId);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }


    return (
        <div className="app">
            <Navbar />
            <div className="content fs-14" >
                <Row className='toolbar m-0'>
                    <Col>
                        {PREVIOUS_PAGE != "" ?
                            <Link onClick={goBack} className='link-primary'>
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver
                            </Link>
                            : ``}

                        <span className={`navbar-icon ${PREVIOUS_PAGE != "" ? `ms-4` : ``}`}><FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon></span>
                        <span className='ms-2'>{PREVIOUS_PAGE != "" ? `${PREVIOUS_PAGE} > ` : ``}{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <div className='fitContentWidth align-center-vertically'>
                            <FontAwesomeIcon icon={faChalkboardTeacher} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedClassroomOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedClassroomOption}>
                                    {classrooms.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelect(i)}>{opt.degree}º {opt.division}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col className='endRow'>
                        <ActionButton iconFa={faPlus} title={'Nueva clase'} func={handleShowNewClassModal} classExtra='fs-14' />
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    <div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={6} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'left' }}>
                                    Nombre
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Niveles
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Profesor
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>

                    {classes.map((r, i) => (
                        <div key={i}>
                            <div className='d-none d-lg-block'>
                                <Row className='mb-1 pb-1 pt-1 pe-2 ps-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                    <Col md={6} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'left' }}>
                                            <Badge bg='' className='content-table status me-2' style={{ background: `${r.color}` }}><span className='m-1'> </span></Badge>({r.id}) {r.name}
                                        </div>
                                    </Col>
                                    <Col md={1} className='align-center-vertically-v2'>
                                        <div style={{ textAlign: 'center' }}>
                                            {r.subSubjectId != null ? "Si" : "No"}
                                        </div>
                                    </Col>
                                    <Col md={3} className='align-center-vertically-v2'>
                                        {r.ClassTeachers.length > 0 ?
                                            <div style={{ textAlign: 'center' }}>
                                                {r.ClassTeachers.map((t, j) => (
                                                    <p key={j} className='m-0'>{t.name} {t.surname}</p>
                                                ))}
                                            </div>
                                            :
                                            <div style={{ textAlign: 'center' }}>Sin asignar</div>
                                        }
                                    </Col>
                                    <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                        <div style={{ display: 'flex', justifyContent: 'end', witdh: '100%', alignItems: 'center' }}>
                                            <Link to={`/configuracion/cursos/materia/?materia=${r.id}`} >
                                                <Button variant="outline-secondary" className='fs-14'><FontAwesomeIcon className='mr-10' icon={faEye} />Nodales</Button>
                                            </Link>

                                            <Dropdown className='ms-2'>
                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='dropdownToggle'>
                                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleShowAssignTeacherModal(r.id)} ><FontAwesomeIcon icon={faChalkboardTeacher} style={{ marginRight: 10, marginLeft: 2 }} />Asignar docente</Dropdown.Item>
                                                    {r.subSubjectId != null ? <Dropdown.Item onClick={() => handleShowModifyStudentsListModal(r)}><FontAwesomeIcon icon={faUsers} style={{ marginRight: 10, marginLeft: 2 }} />Alumnos</Dropdown.Item> : <></>}
                                                    <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r.id, "Eliminar materia", `¿Deseas eliminar la clase "${r.name}"?`, deleteClass)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ))}
                </Card>
            </div>

            <Modal size='lg' show={showAssignTeacherModal} onHide={handleCloseAssignTeacherModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Asignar docente</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>
                    <div className=''>
                        <div>
                            <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                                <Col md={10} className='align-center-vertically-v2' >
                                    <div>
                                        Nombre
                                    </div>
                                </Col>
                                <Col md={2} className='align-center-vertically-v2' >
                                    <div style={{ textAlign: 'center' }}>

                                    </div>
                                </Col>
                            </Row>
                        </div>
                        {teachers.map((r, i) => (
                            <div key={i}>
                                <div className='d-none d-lg-block'>
                                    <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={10} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className='fitContentWidth'>
                                                    <div className='studentImg circle-container toolBarIcon' style={{ borderColor: 'white' }}>

                                                    </div>
                                                </div>
                                                <p className='classesTime m-0'><p className='m-0'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</p>{/*<p className='m-0' style={{ fontSize: 11, color: 'gray' }}>DNI 12.345.678 - 15 años</p>*/}</p>

                                            </div>
                                        </Col>
                                        <Col md={2} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <ActionButton title='Asignar' iconFa={faCheck} func={() => postAssignTeacher(r.id)}></ActionButton>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseAssignTeacherModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewClassModal} onHide={handleCloseNewClassModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Nueva clase</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14 pt-3 pb-3'>
                    <Row>
                        <Col md={12} className='align-items-center mb-3'>
                            <div className='align-center-vertically'>
                                <FontAwesomeIcon icon={faGraduationCap} className='dropDownIcon' />
                                <Dropdown className='dropdownButton'>
                                    <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-300">
                                        {selectedSubjectOption}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="fs-14" title={selectedSubjectOption}>
                                        {subjects.map((opt, i) => (
                                            <Dropdown.Item key={i} onClick={() => handleSelectSubject(i)}>{opt.name}</Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseNewClassModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="primary" className='fs-14' onClick={postClass}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            {showConfirmDeleteModal.state &&
                <div>
                    <div style={{ opacity: 0.5, zIndex: 1050 }}></div>
                    <Modal show={showConfirmDeleteModal.state} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" className="custom-backdrop" style={{ zIndex: 1051 }}>
                        <Modal.Header closeButton>
                            <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>{showConfirmDeleteModal.modalTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='pt-4 pb-4'>
                            <div className='' style={{ textAlign: 'center' }}>{showConfirmDeleteModal.modalContent}</div>
                        </Modal.Body>
                        <Modal.Footer className='p-2'>
                            <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                            <Button variant="danger" className='fs-14 me-2' onClick={showConfirmDeleteModal.funct}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }

            <Modal size='lg' show={showModifyStudentsListModal} onHide={handleCloseModifyStudentsListModal} centered backdrop="static" style={{ zIndex: 1045 }}>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faChalkboardTeacher} style={{ minWidht: '20px' }} /><Modal.Title>Alumnos</Modal.Title>
                </Modal.Header>
                <Modal.Body className='fs-14'>

                    <Row className='mb-3'>
                        {studentsBySubSubject != null && <InfoField content={subjectToModify.name} title='Materia' />}
                    </Row>

                    {areModalStudentsLoaded ? <div>
                        <Row className='pt-2 pb-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    #
                                </div>
                            </Col>
                            <Col md={9} className='align-center-vertically-v2' >
                                <div>
                                    Nombre
                                </div>
                            </Col>

                            <Col md={2} className='align-center-vertically-v2' >

                            </Col>
                        </Row>
                        {studentsBySubSubject != null && studentsBySubSubject.map((r, i) => (
                            <div>
                                <div className='d-none d-lg-block'>
                                    <Row className='mb-1 pt-1 pb-1 ps-2 pe-2' style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Col md={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                {1 + i}
                                            </div>
                                        </Col>
                                        <Col md={7} className='align-center-vertically-v2'>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <div className='studentImg circle-container me-2' style={{ borderColor: 'white' }}>
                                                    {/* <img src={r.avatarFile} alt={r.name} /> */}
                                                </div>

                                                <p className='classesTime m-0'><p className='m-0'>{r.surname ? r.surname.toUpperCase() : ""}, {r.name}</p><p className='m-0' style={{ fontSize: 11, color: 'gray' }}></p></p>

                                            </div>
                                        </Col>
                                        <Col md={4} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                {!r.belongsThisClass && !r.belongsOtherClass && <Button variant="primary" className='fs-14' onClick={() => postAddStudentToSubSubject(r.id)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Añadir</Button>}
                                                {r.belongsThisClass && <Button variant="danger" className='fs-14' onClick={() => handleShowConfirmDeleteModal(r.id, "Eliminar alumno", `¿Deseas eliminar a ${r.surname ? r.surname.toUpperCase() : ""}, ${r.name} de ${subjectToModify.name}?`, deleteStudentFromSubSubject)}><FontAwesomeIcon className='mr-10' icon={faTrash} />Eliminar</Button>}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </div> :
                        <div className='justify-content-center'>
                            <Loading animation={'border'} classExtra='loading-100' />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14' onClick={handleCloseModifyStudentsListModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Classes