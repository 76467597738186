import { faArrowLeft, faCalendar, faChalkboardTeacher, faCheck, faCog, faDownload, faEllipsisV, faEnvelope, faEye, faEyeSlash, faFile, faFileArchive, faFileCircleCheck, faFileCircleExclamation, faInfoCircle, faPen, faPhone, faPlus, faSearch, faTimes, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Row, Table, Form, Dropdown, Spinner } from 'react-bootstrap';
import Navbar from '../../navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation, useHistory, useNavigate } from 'react-router-dom';
import ActionButton from '../../general-components/ActionButton';
import { getUserToken, logout, validateToken } from '../../../controllers/user.controller';
import { DEL, GET, POST } from '../../../controllers/endpoints.controller';
import { getTextColorForBackground, timeSince } from '../../../tools/tools';
import moment from 'moment';
import NotFound from '../../general-components/NotFound';
import Loading from '../../general-components/Loading';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import CalendarList from '../../general-components/CalendarList';

function Registration() {
    const PAGE_TITLE = "Inscripciones";
    const PAGE_ICON = faFileCircleCheck;

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const option = params.get('estado');
        if (option) {
            setSelectedStatusOption(option);
        }
    }, [location.search]);

    useEffect(() => {
        document.title = `Tasky - ${PAGE_TITLE}`;
        validateToken();

        getPotentialParents("Todos");
        getInterviews();
    }, []);

    const [interviews, setInterviews] = useState([]);


    const [isTableLoaded, setIsTableLoaded] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [status, setStatus] = useState(['Todos', 'Pendiente', 'Completado']);
    const [selectedStatusOption, setSelectedStatusOption] = useState('Todos');
    const handleSelectStatus = (eventKey) => {
        setSelectedStatusOption(eventKey);
        getPotentialParents(eventKey);
        navigate({
            pathname: location.pathname,
            search: `?estado=${eventKey}`,
        });
    };

    const [potentialParent, setPotentialParent] = useState(null);

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [deletePotentialParentId, setdeletePotentialParentId] = useState(null);
    const [deletePotentialParentName, setdeletePotentialParentName] = useState("");
    const handleCloseConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
    const handleShowConfirmDeleteModal = (id, name) => {
        setdeletePotentialParentId(id);
        setdeletePotentialParentName(name);
        setShowConfirmDeleteModal(true);
    };

    const deletePotentialParent = async function (id) {
        const req = await DEL(`/registration/potentialCustomer/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 201) {
            console.log(res)
            getPotentialParents(selectedStatusOption)
            handleCloseConfirmDeleteModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const getParamValue = (params, nameParam) => {
        for (let sd of params) {
            if (sd.param.trim() === nameParam) {
                return sd.value;
            }
        }
    }

    const [lastSearch, setLastSearch] = useState("null");
    const getPotentialParents = async function (status) {
        let chain = document.getElementById('textSearch').value;

        if (chain.trim() == "") {
            chain = "null";
        }

        if (chain.length >= 3 || chain == "null") {
            setLastSearch(chain);
            setIsTableLoaded(false)
            const req = await GET(`/registration/potentialCustomersByLevelChain&Status/${chain}/${status}`, getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                console.log(res)
                setPotentialParent(res)
                setIsTableLoaded(true);
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const getInterviews = async function () {
        const req = await GET(`/registration/potentialCustomers/interviews/byLevel/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setInterviews(res);
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            getPotentialParents(selectedStatusOption)
        }
    };

    const [shownewRegistrationModal, setShownewRegistrationModal] = useState(false);
    const handleClosenewRegistrationModal = () => setShownewRegistrationModal(false);
    const handleShownewRegistrationModal = () => {
        setShownewRegistrationModal(true);
    };

    const [showCalendarInterviewsModal, setShowCalendarInterviewsModal] = useState(false);
    const handleCloseCalendarInterviewsModal = () => setShowCalendarInterviewsModal(false);
    const handleShowCalendarInterviewsModal = () => {
        setShowCalendarInterviewsModal(true);
    };




    const processIcon = (icon) => {
        switch (icon) {
            case "PHON":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faPhone}></FontAwesomeIcon>;
            case "WHAT":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faWhatsapp}></FontAwesomeIcon>;
            case "EMAI":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faEnvelope}></FontAwesomeIcon>;
            case "MEET":
                return <FontAwesomeIcon style={{ width: 10 }} icon={faUsers}></FontAwesomeIcon>;
            default:
                return <FontAwesomeIcon style={{ width: 10 }} icon={faInfoCircle}></FontAwesomeIcon>;
        }
    }

    const handleDownload = async () => {
        let chain = lastSearch;
        if (chain.trim() == "") {
            chain = "null";
        }
        const req = await GET(`/registration/potentialCustomers/excel/byLevelChain&Status/${chain}/${selectedStatusOption}`, getUserToken());
        const res = await req.json();
    
        if (req.status === 200) {
            console.log(res)
            const linkSource = res.data;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = "Inscripciones.xlsx";
            downloadLink.click();
            return true;
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
            return false;
        }
    }

    return (
        <div className="app">
            <Navbar />
            <div className="content">
                <Row className='toolbar m-0'>
                    <Col>
                        <FontAwesomeIcon height={35} className='navbar-icon-content' icon={PAGE_ICON}></FontAwesomeIcon>
                        <span className='ms-2'>{PAGE_TITLE}</span>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col className='fitContentWidth'>
                        <div className='align-center-vertically '>
                            <FontAwesomeIcon icon={faFile} className='dropDownIcon' style={{ height: 16 }} />
                            <Dropdown className='dropdownButton'>
                                <Dropdown.Toggle className="dropdownFilter txt-left fs-14 wi-200">
                                    {selectedStatusOption}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-14" title={selectedStatusOption}>
                                    {status.map((opt, i) => (
                                        <Dropdown.Item key={i} onClick={() => handleSelectStatus(opt)}>{opt}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                    <Col>
                        <div className='align-center-vertically '>
                            <Form.Control className='textInputGray fs-14 wi-200' placeholder="Buscar..." id="textSearch" onKeyDown={handleKeyPress} />
                            <ActionButton iconFa={faSearch} title='Buscar' func={() => getPotentialParents(selectedStatusOption)} classExtra='ms-2' />
                        </div>
                    </Col>
                    <Col className='endRow'>
                        {isTableLoaded &&  <ActionButton title='Descargar listado' variant='secondary' classExtra='me-2' iconFa={faDownload} func={handleDownload}></ActionButton>}
                        <ActionButton title='Nueva solicitud' iconFa={faPlus} func={handleShownewRegistrationModal}></ActionButton>
                        <Dropdown className='ms-2'>
                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {!showDetails && <Dropdown.Item onClick={() => setShowDetails(true)}><FontAwesomeIcon icon={faEye} style={{ marginRight: 10, marginLeft: 2 }} />Mostrar detalles</Dropdown.Item>}
                                {showDetails && <Dropdown.Item onClick={() => setShowDetails(false)}><FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: 10, marginLeft: 2 }} />Ocultar detalles</Dropdown.Item>}
                                <Dropdown.Item onClick={handleShowCalendarInterviewsModal}><FontAwesomeIcon icon={faCalendar} style={{ marginRight: 12, marginLeft: 4 }} />Ver entrevistas</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Card className='cards mt-3'>
                    {isTableLoaded ? potentialParent.length != 0 ? <><div>
                        <Row className='p-2 mb-2' style={{ color: 'white', backgroundColor: '#3680F4', borderRadius: 8 }}>
                            <Col md={1} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Fecha
                                </div>
                            </Col>
                            <Col md={3} className='align-center-vertically-v2'>
                                <div>
                                    Adulto responsable
                                </div>
                            </Col>
                            <Col md={4} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Alumnos
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2' >
                                <div style={{ textAlign: 'center' }}>
                                    Inactiv.
                                </div>
                            </Col>
                            <Col md={2} className='align-center-vertically-v2'>
                                <div style={{ textAlign: 'center' }}>
                                    Estado
                                </div>
                            </Col>
                            <Col md={1} className='align-center-vertically-v2'>
                            </Col>
                        </Row>
                    </div>
                        {potentialParent != null && potentialParent.map((r, i) => (
                            <div key={i}>
                                <div className='d-none d-lg-block'>
                                    <Row className={`${showDetails ? 'mb-2' : 'mb-1'} ps-2 pe-2 pt-1 pb-1`} style={{ background: '#F0F0F0', borderRadius: 8 }}>
                                        <Row className={showDetails ? "mb-2" : ""}>
                                            <Col md={1} className='align-center-vertically-v2 ps-0 pe-0'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {moment(r.createdAt).format("DD/MM/YY")}<br />
                                                    {moment(r.createdAt).format("HH:mm")}
                                                </div>
                                            </Col>
                                            <Col md={3} className='align-center-vertically-v2' >
                                                <div style={{ textAlign: 'left' }} className='pt-1 pb-1'>
                                                    <p className='m-0'>{r.surname ? r.surname.toUpperCase() : ''}, {r.name}</p>
                                                    <div className='m-0 fs-11' style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                                                        <FontAwesomeIcon icon={faEnvelope} className='me-1' style={{ height: 10, color: '#6c757d' }} />{getParamValue(r.ParentData, "Correo electrónico")}
                                                    </div>
                                                    <div className='m-0 fs-11' style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                                                        <FontAwesomeIcon icon={faPhone} className='me-1' style={{ height: 10, color: '#6c757d' }} />{getParamValue(r.ParentData, "Teléfono")}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={4} className='align-center-vertically-v2 pb-1' >
                                                {r.PotentialStudents && r.PotentialStudents.map((s, i) => (
                                                    <div style={{ textAlign: 'left' }}>
                                                        <p style={{ marginTop: 4 }} className='mb-0 fs-14'>
                                                            {getParamValue(s.StudentData, "Nivel al que desea ingresar").includes("Primari") && <Badge bg='success' className="" style={{ fontWeight: 400 }}>Primaria</Badge>}
                                                            {getParamValue(s.StudentData, "Nivel al que desea ingresar").includes("Inic") && <Badge bg='warning' className="" style={{ fontWeight: 400, color: 'black' }}>Inicial</Badge>}
                                                            {getParamValue(s.StudentData, "Nivel al que desea ingresar").includes("Medio") && <Badge bg='danger' className="" style={{ fontWeight: 400 }}>Secundaria</Badge>}
                                                            <Badge bg="" className="ms-1" style={{ fontWeight: 400, background: s.Status.color, color: getTextColorForBackground(s.Status.color) }}>{s.Status.name}</Badge>
                                                        </p>
                                                        <p style={{ marginBottom: 1 }} className=''>{s.surname ? s.surname.toUpperCase() : ''}, {s.name}</p>
                                                    </div>
                                                ))}
                                            </Col>
                                            <Col md={1} className='align-center-vertically-v2' >
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.lastActivity != null ? timeSince(r.lastActivity) : '-'}
                                                </div>
                                            </Col>
                                            <Col md={2} className='align-center-vertically-v2'>
                                                <div style={{ textAlign: 'center' }}>
                                                    {r.isSeen ?
                                                        <Badge bg='success' style={{ fontWeight: 400 }} className=''>Visto</Badge>
                                                        :
                                                        <Badge bg='danger' style={{ fontWeight: 400 }} className=''>Sin ver</Badge>
                                                    }
                                                    <br />
                                                    <Badge bg='' style={{ background: r.color, color: getTextColorForBackground(r.color), fontWeight: 400 }} className=''>{r.status}</Badge>
                                                </div>
                                            </Col>
                                            <Col md={1} className='align-center-vertically-v2 pe-2'>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                    <Link to={`/inscripciones/detalle/?id=${r.id}`}>
                                                        <Button variant="secondary">
                                                            <span><FontAwesomeIcon icon={faEye} /></span>
                                                        </Button>
                                                    </Link>
                                                    <Dropdown className='ms-2'>
                                                        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                                            <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => handleShowConfirmDeleteModal(r.id, r.name + " " + r.surname)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Col>
                                        </Row>
                                        {showDetails &&


                                            <Row style={{ borderTop: 'solid 1px #C8C8C8' }} className='pt-2'>
                                                {r.Meetings.map((m, i) => (
                                                    <>

                                                        <Col md={3} className='p-1'>
                                                            <div className='meeting-card ps-3 pe-3 pt-2 pb-1 mb-1'>
                                                                <p className='mb-1 fs-11'>{moment(m.date).format('DD/MM/YY')}</p>
                                                                <p className='mb-1'><Badge bg='' style={{ paddingTop: 1, paddingBottom: 3, fontWeight: 400, background: m.MeetingType.color, color: getTextColorForBackground(m.MeetingType.color) }}>{processIcon(m.MeetingType.icon)} {m.MeetingType.name}</Badge></p>
                                                                {(m.isCanceled != null || r.isSuccessful != null) ?
                                                                    <>
                                                                        {(m.isCanceled == false && m.isSuccessful == false) ? <Badge bg='warning' style={{ fontWeight: 400, color: 'black' }}>Pendiente {m.scheduledDate ? `(${moment(m.scheduledDate).format('DD/MM/YY HH:mm')})` : ''}</Badge> : <></>}
                                                                        {(m.isCanceled == true) ? <Badge bg='danger' style={{ fontWeight: 400 }}>Cancelada {m.scheduledDate ? `(${moment(m.scheduledDate).format('DD/MM/YY HH:mm')})` : ''}</Badge> : <></>}
                                                                        {(m.isSuccessful == true) ? <Badge bg='success' style={{ fontWeight: 400 }}>Realizada {m.scheduledDate ? `(${moment(m.scheduledDate).format('DD/MM/YY HH:mm')})` : ''}</Badge> : <></>}
                                                                    </>
                                                                    :
                                                                    <></>
                                                                }
                                                                <p className='mb-2'>{m.content}</p>
                                                            </div>
                                                        </Col>

                                                    </>
                                                ))}
                                                {r.Meetings.length == 0 ?
                                                    <Col className='align-center-vertically-v2 pb-1'>
                                                        <div style={{ textAlign: 'center' }}>
                                                            Sin interacciones
                                                        </div>
                                                    </Col>
                                                    : <></>}


                                            </Row>
                                        }

                                    </Row>
                                </div>
                            </div>
                        ))}
                    </> : <div className='justify-content-center' style={{ alignItems: 'center' }}>
                        <NotFound iconFa={faFileCircleExclamation} text='No se han encontrado inscripciones' classExtra={'mt-5 mb-5'} />
                    </div> :
                        <div className='justify-content-center' style={{ alignItems: 'center' }}>
                            <Loading animation={'border'} classExtra='loading-100' />
                        </div>
                    }
                </Card>
            </div>

            <Modal show={showConfirmDeleteModal} onHide={handleCloseConfirmDeleteModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faTrash} style={{ minWidht: '20px' }} /><Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-4 pb-4'>
                    <span className=''>¿Deseas eliminar la inscripción de "{deletePotentialParentName}"?</span>
                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseConfirmDeleteModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                    <Button variant="danger" className='fs-14 me-2' onClick={() => deletePotentialParent(deletePotentialParentId)}><FontAwesomeIcon className='mr-10' icon={faCheck} />Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal size={'lg'} show={shownewRegistrationModal} onHide={handleClosenewRegistrationModal} centered backdrop="static" >
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faFile} style={{ minWidht: '20px' }} /><Modal.Title>Nueva solicitud de inscripción</Modal.Title>
                </Modal.Header>
                <iframe loading="lazy" className='d-none d-lg-block' src="https://tasky.digital/forms/caba-fasta-svp.html?notificate=false" style={{ width: '100%', height: '1500px' }} ></iframe>
                <iframe loading="lazy" className='d-block d-lg-none' src="https://tasky.digital/forms/caba-fasta-svp.html?notificate=false" style={{ width: '100%', height: '1700px' }} ></iframe>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleClosenewRegistrationModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <Modal size='lg' show={showCalendarInterviewsModal} onHide={handleCloseCalendarInterviewsModal} centered>
                <Modal.Header closeButton>
                    <FontAwesomeIcon height={35} className='dropModalCont dropDownIcon' icon={faCalendar} style={{ minWidht: '20px' }} /><Modal.Title>Entrevistas</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='mb-3'>
                        <CalendarList events={interviews} type={"REG-INTERVIEWS"}></CalendarList>
                    </div>


                </Modal.Body>
                <Modal.Footer className='p-2'>
                    <Button variant="outline-secondary" className='fs-14 me-2' onClick={handleCloseCalendarInterviewsModal}><FontAwesomeIcon className='mr-10' icon={faTimes} />Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Registration