import React, { useEffect } from 'react'
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { getChilds, validateUser } from '../../controllers/user.controller';
import { GET } from '../../controllers/endpoints.controller';
import { getUserToken, logout } from '../../controllers/user.controller';

export default function Index() {

    useEffect(() => {
        validateToken();
    }, []);

    const validateToken = async function () {
        const token = localStorage.getItem("userToken");

        if (token !== undefined && token !== null) {
            const req = await GET('/sso/token', getUserToken());
            const res = await req.json();

            if (req.status === 200) {
                if (token.length < 10) {
                    logout();
                } else {
                    getChilds();
                }
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        } else {
            logout();
        }
    }

    return (
        <div>
            Cargando...
        </div>
    );
}